import React from "react";
import { Modal, Row, Col, Typography, Tag, } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import VocabDetailCard from "./VocabDetailCard";

interface Category {
  name: string;
}

interface VocabDetail {
  name: string;
  transcription: string;
  category: Category[];
  meaning?: string;
  example?: string;
  synonym?: string;
  antonym?: string;
  phrase?: string;
  context?: string;
  note?: string;
  image?: string;
}

interface VocabDetailModalProps {
  isModalDetailOpen: boolean;
  handleCancelDetail: () => void;
  vocabDetail: VocabDetail;
  isMobile: boolean;
  handleCollapseChange: (vocabName: string) => void;
}

const VocabDetailModal: React.FC<VocabDetailModalProps> = ({
  isModalDetailOpen,
  handleCancelDetail,
  vocabDetail,
  isMobile,
  handleCollapseChange,
}) => {
  const colors = ["blue", "green", "red", "purple", "orange"];

  return (
    <Modal
      open={isModalDetailOpen}
      centered
      footer={false}
      width={1000}
      onCancel={handleCancelDetail}
      zIndex={99999}
      closeIcon={false}
      style={isMobile ? { padding: 16 } : undefined}
    >
      {isMobile ? (
        <div>
          <div className="animated-border">
            <Row style={{ alignItems: "center" }}>
              <Typography.Title
                level={2}
                style={{
                  margin: 0,
                  fontSize: 22,
                  fontWeight: 700,
                  color: "var(--primary-color)",
                  width: "100%",
                  textAlign: "left",
                  marginBottom: 6,
                  textTransform: "capitalize",
                }}
              >
                {vocabDetail.name}
              </Typography.Title>
              <Col xs={18}>
                <p style={{ margin: 0, fontSize: "18px" }}>
                  {vocabDetail.transcription}
                </p>
                <div>
                  {vocabDetail.category.map((category, index) => (
                    <Tag
                      key={index}
                      color={colors[index % colors.length]}
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginTop: "8px",
                        marginRight: "4px",
                      }}
                    >
                      {category.name}
                    </Tag>
                  ))}
                </div>
              </Col>
              <Col xs={6}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SoundOutlined
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCollapseChange(vocabDetail.name);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="animated-border-inner"></div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "20px" }}>
          <div className="animated-border">
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <Typography.Title
                level={2}
                style={{
                  margin: 0,
                  fontSize: "28px",
                  fontWeight: 700,
                  color: "var(--primary-color)",
                }}
              >
                {vocabDetail.name}
              </Typography.Title>
              <p style={{ margin: 0, fontSize: "18px" }}>
                {vocabDetail.transcription}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "color 0.3s",
                }}
                onClick={() => handleCollapseChange(vocabDetail.name)}
                onMouseEnter={(e) => (e.currentTarget.style.color = "blue")}
                onMouseLeave={(e) => (e.currentTarget.style.color = "black")}
              >
                <SoundOutlined style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div>
              {vocabDetail.category.map((category, index) => (
                <Tag
                  key={index}
                  color={colors[index % colors.length]}
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    marginTop: "8px",
                    marginRight: "4px",
                  }}
                >
                  {category.name}
                </Tag>
              ))}
            </div>
            <div className="animated-border-inner"></div>
          </div>
        </div>
      )}

      <Row gutter={[16, 0]} style={{ maxHeight: "50vh", overflowY: "auto" }}>
        {vocabDetail.meaning && (
          <VocabDetailCard title="meaning" desc={vocabDetail.meaning} />
        )}

        {vocabDetail.example && (
          <VocabDetailCard title="example" desc={vocabDetail.example}  sound handleCollapseChange={handleCollapseChange}/>
        )}

        {vocabDetail.synonym && (
          <VocabDetailCard title="synonym" desc={vocabDetail.synonym} />
        )}

        {vocabDetail.antonym && (
          <VocabDetailCard title="antonym" desc={vocabDetail.antonym} />
        )}

        {vocabDetail.phrase && (
          <VocabDetailCard title="phrase" desc={vocabDetail.phrase} />
        )}

        {vocabDetail.context && (
          <VocabDetailCard title="context" desc={vocabDetail.context} />
        )}

        {vocabDetail.note && (
          <VocabDetailCard title="note" desc={vocabDetail.note} />
        )}

        {vocabDetail.image && (
          <VocabDetailCard title="image" desc={vocabDetail.image} />
        )}
      </Row>
    </Modal>
  );
};

export default VocabDetailModal;
