import React, { useState } from "react";
import { Menu } from "antd";
import { MdDashboard, MdGrain, MdRateReview, MdTopic } from "react-icons/md";
import { LuCornerDownRight } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import { IoAnalyticsSharp } from "react-icons/io5";
import UseRespon from "../../../../hook/useResponsive";
import { useTranslation } from "react-i18next";

const { SubMenu, ItemGroup } = Menu;

interface SliderAppProps {
  onItemClick?: () => void;
}

export default function SliderApp({ onItemClick }: SliderAppProps) {
  const { isMobile } = UseRespon();
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [hoverKey, setHoverKey] = useState<string | null>(null);

  const handleClick = ({ key }: { key: string }) => {
    setSelectedKeys([key]);
    onItemClick?.();
  };

  const handleMouseEnter = (key: string) => {
    setHoverKey(key);
  };

  const handleMouseLeave = () => {
    setHoverKey(null);
  };

  const menuGroups = [
    {
      groupName: t("overview"),
      items: [
        { key: "1", icon: <MdDashboard size={16} />, label: t("layout.home.home"), path: "/" },
      ]
    },
    {
      groupName:t("learning"),
      items: [
        {
          key: "2",
          icon: <IoAnalyticsSharp size={16} />,
          label: t("word"),
          path: "/page2/detail1",
          subItems: [
            { key: "2-2", label: t("vocab"), path: "/Vocab" },
            { key: "2-3", label: t("remember"), path: "Vocab/Remember" },
            { key: "2-4", label: t("notRemember"), path: "Vocab/NotRemember" },
          ],
        },
        {
          key: "3",
          icon: <MdGrain size={16} />,
          label: t("grammar"),
          subItems: [
            { key: "3-1", label: t("wordform"), path: "Grammar/Wordform" },
          ],
        },
        { key: "4", icon: <MdTopic size={16} />, label: t("topic"), path: "/Topic" },
      ]
    },
    {
      groupName: t("grammar"),
      items: [
        { key: "5", icon: <MdRateReview size={16} />, label: t("review"), path: "/Review" },
      ]
    }
  ];

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      onClick={handleClick}
      style={{
        padding: isMobile ? undefined : 10,
        background: `var(--backgroud-color)`,
        minHeight: "65vh",
        maxHeight: "65vh",
        overflowY: "auto",
      }}
      className="menuSlider"
    >
      {menuGroups.map((group) => (
        <ItemGroup key={group.groupName} title={group.groupName}>
          {group.items.map(({ key, icon, label, path, subItems }) =>
            subItems ? (
              <SubMenu key={key} icon={icon} title={label}>
                {subItems.map(({ key: subKey, label: subLabel, path: subPath }) => {
                  const hasIcon = selectedKeys.includes(subKey) || hoverKey === subKey;

                  return (
                    <Menu.Item
                      key={subKey}
                      icon={
                        hasIcon ? (
                          <LuCornerDownRight size={16} style={{ opacity: hoverKey === subKey ? 0.5 : 1 }} />
                        ) : null
                      }
                      style={{ paddingLeft: hasIcon ? 24 : 48, fontWeight: hasIcon ? 600 : 400 }}
                      onMouseEnter={() => handleMouseEnter(subKey)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link to={subPath}>{subLabel}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : (
              <Menu.Item key={key} icon={icon}>
                <Link to={path}>{label}</Link>
              </Menu.Item>
            )
          )}
        </ItemGroup>
      ))}
    </Menu>
  );
}