import React from "react";
import TableComponent from "../../../global/Custom/TableComponent/TableComponent";
import CardComponent from "../../../global/Custom/CardComponent/CardComponent";
import CollapseComponent from "../../../global/Custom/CollapseComponent/CollapseComponent";
import VocabDetailModal from "./Detail/VocabDetailModal";
import { useVocabTable } from "../../../../container/Vocab/VocabTable";
import VocabDeleteModal from "./Detail/VocabDeleteModal";

interface VocabDisplayProps {
  showTable: boolean;
  handleExcel: () => void;
  dataTable:any[];
  data: any[];
  totalVocab: number;
  onChangePage: (pageIndex: number, pageSize: number) => void;
  params: { pageIndex: number; pageSize: number };
  searchValue: string;
  selectedValues: string[];
  selectedValuesTopic: string[];
  showModalDetail: (id: string) => void;
  showModalDelete: (id: string) => void;

  handleCollapseChange: (key: string) => void;
  isMobile: boolean;
  vocabDetail: any;
  isModalDetailOpen: boolean;
  isModalDetlete: boolean;

  handleCancelDetail: () => void;
  handleCancelDelete: () => void;
  handleOkDelete: () => void;

}

const VocabDisplay: React.FC<VocabDisplayProps> = ({
  showTable,
  handleExcel,
  dataTable,
  data,
  totalVocab,
  onChangePage,
  params,
  searchValue,
  selectedValues,
  selectedValuesTopic,
  showModalDetail,
  showModalDelete,
  handleCollapseChange,
  isMobile,
  vocabDetail,
  isModalDetailOpen,
  isModalDetlete,
  handleCancelDetail,
  handleCancelDelete,
  handleOkDelete,
  
}) => {
  const { columns } = useVocabTable({ showModalDetail,showModalDelete });
  return (
    <>
      {isMobile ? (
        <CollapseComponent
          data={data}
          totalPage={totalVocab}
          onChangePage={onChangePage}
          pageIndex={params.pageIndex}
          pageSize={params.pageSize}
          handleCollapse={handleCollapseChange}
          exportOnClick={handleExcel}
          showModalDetail={showModalDetail}
          showModalDelete={showModalDelete}
        />
      ) : (
        <>
          {showTable ? (
            <TableComponent
              exportOnClick={handleExcel}
              columns={columns}
              data={dataTable}
              
              totalPage={totalVocab}
              onChangePage={onChangePage}
              pageIndex={params.pageIndex}
              pageSize={params.pageSize}
              
            />
          ) : (
            <CardComponent
              exportOnClick={handleExcel}
              data={data}
              onCardClick={showModalDetail}
              handleCollapseChange={handleCollapseChange}
              totalPage={totalVocab}
              onChangePage={onChangePage}
              pageIndex={params.pageIndex}
              pageSize={params.pageSize}
              search={searchValue}
              categoryId={selectedValues}
              topicId={selectedValuesTopic}
             showModalDelete={showModalDelete}
             showModalDetail={showModalDetail}
            />
          )}
        </>
      )}
      <VocabDetailModal
        isModalDetailOpen={isModalDetailOpen}
        handleCancelDetail={handleCancelDetail}
        vocabDetail={vocabDetail}
        isMobile={isMobile}
        handleCollapseChange={handleCollapseChange}
      />
      <VocabDeleteModal
        isModalOpen={isModalDetlete}
        handleCancel={handleCancelDelete}
        handleOkDelete={handleOkDelete}
       
      />
    </>
  );
};

export default VocabDisplay;
