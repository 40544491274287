import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useLocation } from "react-router-dom";
import { useTopic } from "../../../hook/topic/useTopic";
import TopicVocab from "./TopicVocab";

export default function TopicDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idTopic = queryParams.get("id") || undefined;
  const { topicDetail,topicCount } = useTopic({ type: 'topic', idTopic:idTopic });

  return (
    <div>
      <TitleComponent
        title={topicDetail.name}
        type="primary"
        icon={false}
        data={topicCount}
        textSub=" từ vựng"
      />
     <TopicVocab topicId={idTopic}/>
    </div>
  );
}
