import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { getVocabById, getVocabByUserIdAndTopic, clearData } from '../../component/redux/action/vocab';
import { dispatch, useSelector } from '../../component/redux/store';

export const useTopicVocab = (
  searchValue: string,
  selectedValues: string[],
  userId: string | undefined,
  selectedIdDetail: string | null,
  pageIndex: number,
  pageSize: number,
  topicId: string | undefined,
  isRemember?: boolean,
) => {
  const { totalVocabTopic, vocabDetail, vocabTopicList } = useSelector((state) => state.vocab);
  const debouncedSearchRef = useRef<ReturnType<typeof debounce> | null>(null);

  // Debounce logic for fetching vocab
  useEffect(() => {
    debouncedSearchRef.current = debounce(
      (userId: string, search: string, topicId: string, pageIndex: number, pageSize: number, isRemember?: boolean) => {
        if (userId && topicId) {
          const payload = {
            userId,
            topicId,
            search,
            categoryIds: selectedValues,  // Ensure `selectedValues` is being used correctly
            pageIndex,
            pageSize,
            isRemember,
          };
          dispatch(getVocabByUserIdAndTopic(payload));
        }
      },
      500
    );

    // Cleanup debounce when component unmounts
    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [selectedValues]); // Trigger effect when selectedValues change

  // Effect to trigger vocab fetch
  useEffect(() => {
    if (userId && topicId) {
      debouncedSearchRef.current?.(userId, searchValue, topicId, pageIndex, pageSize, isRemember);
    }
  }, [userId, searchValue, selectedValues, topicId, pageIndex, pageSize, isRemember]);

  // Fetch vocab detail when selectedIdDetail changes
  useEffect(() => {
    if (selectedIdDetail) {
      dispatch(getVocabById(selectedIdDetail));
    }
  }, [selectedIdDetail]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, []);

  return { totalVocabTopic, vocabDetail, vocabTopicList };
};
