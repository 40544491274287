import { Button, Modal } from 'antd';
import React from 'react';

interface VocabDeleteModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  handleOkDelete: () => void;
 
}

export default function VocabDeleteModal({
  isModalOpen,
  handleCancel,
  handleOkDelete,
 
}: VocabDeleteModalProps) {
  return (
    <Modal
      title="Xóa từ"
      open={isModalOpen}
      centered
      footer={null}
      width={330}
      onCancel={handleCancel}
      zIndex={99999}
    >
      <p>Bạn có chắc chắn xoá từ này không?</p>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
        <Button style={{ padding: '0 20px' }} onClick={handleCancel}>
          Hủy
        </Button>
        <Button type="primary" onClick={() => handleOkDelete()}> 
          Tiếp tục
        </Button>
      </div>
    </Modal>
  );
}
