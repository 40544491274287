import React from "react";
import UseRespon from "../../../hook/useResponsive";
import VocabSearch from "../Word/VocabPage/VocabSearch";
import VocabDisplay from "../Word/VocabPage/VocabDisplay";
import useTopicVocabVocabContainer from "../../../container/Topic/TopicVocabCon";

type Props = {

  topicId?: string;
};

export default function TopicVocab({

  topicId,
}: Props) {
  const { isMobile } = UseRespon();

  const {
    vocabTopicList,
    totalVocabTopic,
    params,
    searchValue,
    selectedValues,
    selectedValuesTopic,
    showTable,
    setSelectedValues,
    setSelectedValuesTopic,
    setShowTable,
    handleExcel,
    onChangePage,
    showModalDetail,
    showModalDelete,
    handleCollapseChange,
    handleInputChange,
    optionsTopic,
    optionsWordform,
    vocabDetail,
    isModalDetailOpen,
    isModalDelete,
    handleCancelDetail,
    handleCancelDelete,
    dataTable,
    handleOkDelete

  } = useTopicVocabVocabContainer(topicId);

  return (
    <div>
      <VocabSearch
        searchValue={searchValue}
        selectedValues={selectedValues}
        selectedValuesTopic={selectedValuesTopic}
        handleInputChange={handleInputChange}
        handleSelectChange={setSelectedValues}
        handleSelectChangeTopic={setSelectedValuesTopic}
        setShowTable={setShowTable}
        showTable={showTable}
        optionsWordform={optionsWordform}
        optionsTopic={optionsTopic}
        isMobile={isMobile}
        handleExport={handleExcel}

      />
      <VocabDisplay
        showTable={showTable}
        handleExcel={handleExcel}
        dataTable={dataTable}
        data={vocabTopicList}
        totalVocab={totalVocabTopic}
        onChangePage={onChangePage}
        params={params}
        searchValue={searchValue}
        selectedValues={selectedValues}
        selectedValuesTopic={selectedValuesTopic}
        showModalDetail={showModalDetail}
        handleCollapseChange={handleCollapseChange}
        isMobile={isMobile}
        handleCancelDetail={handleCancelDetail}
        isModalDetailOpen={isModalDetailOpen}
        vocabDetail={vocabDetail}
        isModalDetlete={isModalDelete}
        showModalDelete={showModalDelete}
        handleCancelDelete={handleCancelDelete}
      
        handleOkDelete={handleOkDelete}
      />
    </div>
  );
}
