import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { getVocabById, clearData, getVocabByUserIdAndCategory } from '../../component/redux/action/vocab';
import { dispatch, useSelector } from '../../component/redux/store';

export const useWordformVocab = (
  searchValue: string,
  selectedValuesTopic: string[],
  userId: string | undefined,
  selectedIdDetail: string | null,
  pageIndex: number,
  pageSize: number,
  categoryId: string | undefined,
  isRemember?: boolean,
) => {
  const { vocabCategoryList, vocabDetail, totalVocabCategory } = useSelector((state) => state.vocab);
  const debouncedSearchRef = useRef<ReturnType<typeof debounce> | null>(null);

  // Initialize debouncedSearchRef
  useEffect(() => {
    debouncedSearchRef.current = debounce(
      (userId: string, search: string, categoryId: string, pageIndex: number, pageSize: number, isRemember?: boolean) => {
        if (userId && categoryId) {
          const payload = {
            userId,
            categoryId,
            search,
            topicIds: selectedValuesTopic,
            pageIndex,
            pageSize,
            isRemember,
          };
          dispatch(getVocabByUserIdAndCategory(payload));
        }
      },
      500 // 500 ms delay
    );

    // Cleanup the debounce function on unmount
    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [selectedValuesTopic, categoryId, searchValue, userId, pageIndex, pageSize, isRemember]);

  // Call the debounced function whenever dependencies change
  useEffect(() => {
    if (userId && categoryId) {
      debouncedSearchRef.current?.(userId, searchValue, categoryId, pageIndex, pageSize, isRemember);
    }
  }, [userId, searchValue, selectedValuesTopic, categoryId, pageIndex, pageSize, isRemember]);

  // Fetch vocab detail if selectedIdDetail is provided
  useEffect(() => {
    if (selectedIdDetail) {
      dispatch(getVocabById(selectedIdDetail));
    }
  }, [selectedIdDetail]);

  // Clean up data when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, []);

  return { vocabCategoryList, vocabDetail, totalVocabCategory };
};
