import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface TinyEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const TinyEditor: React.FC<TinyEditorProps> = ({ value, onChange }) => {
  const editorRef = useRef<any>(null);

  // Cập nhật giá trị khi TinyMCE content thay đổi
  const handleEditorChange = (content: string) => {
    onChange(content); // Truyền giá trị vào callback onChange của parent component
  };

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getContent()) {
      // Cập nhật lại nội dung của TinyMCE khi props `value` thay đổi
      editorRef.current.setContent(value);
    }
  }, [value]); // Lắng nghe sự thay đổi của props value

  return (
    <Editor
      apiKey="o8vzsvm0ih35hf4wa871dw9occgxnbyol4iwqt8ryqjeeg9m"  // API key của bạn
      init={{
        plugins: [
          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
          'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
          'importword', 'exportword', 'exportpdf'
        ],
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        exportpdf_converter_options: {
          format: 'Letter',
          margin_top: '1in',
          margin_right: '1in',
          margin_bottom: '1in',
          margin_left: '1in'
        },
        exportword_converter_options: {
          document: { size: 'Letter' }
        },
        importword_converter_options: {
          formatting: { styles: 'inline', resets: 'inline', defaults: 'inline' }
        },
        statusbar: false,
      }}
      value={value}  // Truyền giá trị vào editor
      onEditorChange={handleEditorChange}  // Xử lý sự kiện khi nội dung thay đổi
      onInit={(evt, editor) => {
        editorRef.current = editor;  // Lưu trữ ref của editor để truy cập
      }}
    />
  );
};

export default TinyEditor;
