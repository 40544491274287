import React from "react";
import VocabDisplay from "./VocabDisplay";
import UseRespon from "../../../../hook/useResponsive";
import useVocabContainer from "../../../../container/Vocab/VocabContainer";
import VocabSearch from "./VocabSearch";

type Props = {
  isRemember?: boolean;
  categoryId?: string;
  topicId?: string;
};

export default function VocabContainer({
  isRemember,
  categoryId,
  topicId,
}: Props) {
  const { isMobile } = UseRespon();

  const {
    vocabList,
    totalVocab,
    params,
    searchValue,
    selectedValues,
    selectedValuesTopic,
    showTable,
    setSelectedValues,
    setSelectedValuesTopic,
    setShowTable,
    handleExcel,
    onChangePage,
    showModalDetail,
    showModalDelete,
    handleCollapseChange,
    handleInputChange,
    optionsTopic,
    optionsWordform,
    vocabDetail,
    isModalDetailOpen,
    isModalDelete,
    handleCancelDetail,
    handleCancelDelete,
    dataTable,
    handleOkDelete

  } = useVocabContainer(isRemember, categoryId, topicId);

  return (
    <div>
      <VocabSearch
        searchValue={searchValue}
        selectedValues={selectedValues}
        selectedValuesTopic={selectedValuesTopic}
        handleInputChange={handleInputChange}
        handleSelectChange={setSelectedValues}
        handleSelectChangeTopic={setSelectedValuesTopic}
        setShowTable={setShowTable}
        showTable={showTable}
        optionsWordform={optionsWordform}
        optionsTopic={optionsTopic}
        isMobile={isMobile}
        handleExport={handleExcel}
      />
      <VocabDisplay
        showTable={showTable}
        handleExcel={handleExcel}
        dataTable={dataTable}
        data={vocabList}
        totalVocab={totalVocab}
        onChangePage={onChangePage}
        params={params}
        searchValue={searchValue}
        selectedValues={selectedValues}
        selectedValuesTopic={selectedValuesTopic}
        showModalDetail={showModalDetail}
        handleCollapseChange={handleCollapseChange}
        isMobile={isMobile}
        handleCancelDetail={handleCancelDetail}
        isModalDetailOpen={isModalDetailOpen}
        vocabDetail={vocabDetail}
        isModalDetlete={isModalDelete}
        showModalDelete={showModalDelete}
        handleCancelDelete={handleCancelDelete}
      
        handleOkDelete={handleOkDelete}
      />
    </div>
  );
}
