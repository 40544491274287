import React from "react";

import { useTranslation } from "react-i18next";

import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import VocabForm from "./Form/VocabForm";

function VocabUpdate() {
    const {t} = useTranslation()
  return (
    <div>
      <TitleComponent title={t('update')} isExist={false} />
      <VocabForm isEdit={true}/>
    </div>
  );
}

export default VocabUpdate;
