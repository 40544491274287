import React from 'react'
import GlobalAction from '../../screen/Global'

export default function TopicList() {
  return (
    <div>
      <GlobalAction action={true} isTopic={true}/>
    </div>
  )
}
