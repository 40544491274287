import React from "react";
import TitleComponent from "../../../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";
import VocabContainer from "../VocabContainer";

export default function VocabRemember() {
  const { t } = useTranslation();
  return (
    <div>
      <TitleComponent
        title={t("Từ vựng đã nhớ")}
        type="primary"
      />
      <VocabContainer isRemember={true} />
    </div>
  );
}
