import BreadCrumb from "../../item/BreadCrumb/BreadCrumb";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { Link } from "react-router-dom";
import UseRespon from "../../../../hook/useResponsive";
type Props = {
  title: any;
  type?: "primary" | "dashed" | "default" | "text" | "link";
  data?: any;
  to?: any;
  isExist?: boolean;
  isExtra?: boolean;
  handleExtra?: () => void;
  icon?: boolean;
  textSub?:string
};

function TitleComponent({
  title,
  type,
  data,
  to,
  isExist = true,
  icon = true,
  isExtra = false,
  textSub,
  handleExtra,
}: Props) {
  const { isMobile } = UseRespon();
  return (
    <Flex
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: isMobile ? 10: 24,
      }}
    >
      <div>
        <h1
          style={{
            color: `var(--primary-color)`,
            margin: isMobile ? "8px 0 8px 0" : "16px 0  ",
          }}
        >
          {title}
        </h1>
        <BreadCrumb />
      </div>
      {isExist && (
        <Link to={to}>
          <Button
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              height: 42,
              borderRadius: 9,
            }}
            type={type}
          >
            {icon && <PlusOutlined />}
            {data}{textSub}
          </Button>
        </Link>
      )}
      {isExtra && (
        <Button shape="circle" type={type} onClick={handleExtra}>
          <SettingOutlined />
          {data}
        </Button>
      )}
    </Flex>
  );
}

export default TitleComponent;
