import { useLocation } from "react-router-dom";
import { useWordform } from "../../../../hook/wordform/useWordform";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import WordformVocab from "./WordformVocab";
import { CgNotes } from "react-icons/cg";
import { FiCommand } from "react-icons/fi";
import { Flex, Tabs } from "antd";

export default function WordformDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idWordform = queryParams.get("id") || undefined;
  const { wordformDetail } = useWordform({
    type: "wordform",
    idWordform: idWordform,
  });
  const items = [
    {
      key: "1",
      label: (
        <Flex align="center" gap={8}>
          <FiCommand size={14} />
          Từ vựng
        </Flex>
      ),
      children: <WordformVocab categoryId={idWordform} />,
    },
    {
      key: "2",
      label: (
        <Flex align="center" gap={8}>
          <CgNotes size={14} />
          Lý thuyết
        </Flex>
      ),
      children: (
        <p
          style={{ fontSize: 15 }}
          dangerouslySetInnerHTML={{ __html: wordformDetail.desc }}
        ></p>
      ),
    },
  ];
  return (
    <div>
      <TitleComponent
        title={wordformDetail.name}
        type="primary"
        
      />
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
