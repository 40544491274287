// GrammarAnswer.tsx
import React from "react";
import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  List,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import Countdown from "react-countdown";
import useGrammarTest from "../../../hook/useTestUser";
import UseRespon from "../../../hook/useResponsive";
import { CloseOutlined } from '@ant-design/icons';
const {  Text } = Typography;

const GrammarAnswer = () => {
  const {
    isModalVisible,
    words,
    shuffledWords,
    userAnswers,
    currentQuestionIndex,
    isTestComplete,
    startTime,
    testDuration,
    getMeaningByWord,
    handleOkData,
    handleInputChange,
    handleNext,
    handleBack,
    getMaskedWord,
    correctCount,
  } = useGrammarTest();
  const { isMobile } = UseRespon();
  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <span>Hết giờ!</span>;
    } else {
      return (
        <div
          style={{
            border: "3px solid #ff3b30",
            borderRadius: "16px",
            padding: "4px 12px",
            display: "inline-block",
            backgroundColor: "#fff1f0",
            boxShadow: "0 6px 12px rgba(255, 59, 48, 0.4)",
            fontWeight: 700,
            color: seconds % 2 === 0 ? "#d32f2f" : "#ff5722",
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "1px",
            textAlign: "center",
            transition: "color 0.5s ease",
            animation: seconds % 2 === 0 ? "pulse 1s infinite" : "none",
          }}
        >
          <span>{minutes}:</span>
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </div>
      );
    }
  };

  const handleSubmit = () => {
    handleNext();
  };

  const splitWordsIntoColumns = (
    array: string[],
    columnCount: number
  ): string[][] => {
    const columns: string[][] = new Array(columnCount).fill(null).map(() => []);

    array.forEach((word, index) => {
      const columnIndex = index % columnCount;
      columns[columnIndex].push(word);
    });

    return columns;
  };
  const renderCharacterComparison = (correctWord: any, userAnswer: any) => {
    return correctWord.split("").map((char: any, index: any) => {
      const isCorrect = userAnswer[index] === char;
      return (
        <Text
          key={index}
          style={{
            color: isCorrect ? "green" : "red",
            fontWeight: isCorrect ? "bold" : "normal",
          }}
        >
          {userAnswer[index] || "_"}
        </Text>
      );
    });
  };

  const columns = splitWordsIntoColumns(words, 4);

  return (
    <div>
      <Modal
        open={isModalVisible}
        onOk={handleOkData}
        closable={false}
        centered
        width={!isTestComplete ? 700 : 1000}
        footer={false}
      >
        <Flex vertical>
          {!isTestComplete && (
            <Flex
              justify="space-between"
              align="center"
              style={{ borderBottom: "1px solid #ccc", paddingBottom: 5 }}
            >
              <Flex vertical>
                <h3
                  style={{
                    margin: 0,
                    color: `var(--primary-color)`,
                    fontWeight: 500,
                  }}
                >
                  Bài kiểm tra từ vựng
                </h3>
                <p style={{ margin: 0 }}>
                  Câu hỏi {currentQuestionIndex + 1} / {words.length}
                </p>
              </Flex>

              <div style={{ marginLeft: "10px", color: "#888" }}>
                {startTime && (
                  <Countdown
                    date={startTime + testDuration}
                    renderer={renderer}
                  />
                )}
              </div>
            </Flex>
          )}

          {!isTestComplete && shuffledWords[currentQuestionIndex] && (
            <div style={{ paddingTop: 30 }}>
              <Flex justify="center" align="center" vertical>
                <p
                  style={{ fontWeight: 500, fontSize: 18, textAlign: "center" }}
                >
                  {getMaskedWord(
                    words[currentQuestionIndex],
                    userAnswers[currentQuestionIndex]
                  )}
                </p>
                <span
                  style={{
                    color: "#555",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `${getMeaningByWord(words[currentQuestionIndex])}`,
                  }}
                />
              </Flex>
              <Input.TextArea
                placeholder="Nhập từ đúng"
                value={userAnswers[currentQuestionIndex]}
                onChange={(e) => handleInputChange(e.target.value)}
                style={{ marginBottom: 10, marginTop: 30, minHeight: 80 }}
              />
              <Row gutter={20} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <Button
                    block
                    onClick={handleBack}
                    disabled={currentQuestionIndex === 0}
                  >
                    Quay lại
                  </Button>
                </Col>
                <Col span={12}>
                  <Button block onClick={handleSubmit} type="primary">
                    {currentQuestionIndex < words.length - 1
                      ? "Tiếp theo"
                      : "Kết thúc"}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {isTestComplete && (
            <>
              {isMobile ? (
                <div>
                  <Flex justify="space-between" align="center">
                  <h3 style={{ margin: 0 }}>Kết quả kiểm tra:</h3>
                  <CloseOutlined onClick={handleOkData}/>
                  </Flex>
                  <List style={{ height: "400px", overflowY: "auto", }}>
                    {words.map((word, index) => (
                      <List.Item key={index}>
                        <Row
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col xs={11}>
                            <Text>{word}</Text>
                          </Col>
                          <Col xs={11}>
                            {renderCharacterComparison(
                              word,
                              userAnswers[index]
                            )}
                          </Col>
                          <Col xs={2}>
                            {userAnswers[index] === word ? (
                              <Text style={{ color: "greend" }}>Đúng</Text>
                            ) : (
                              <Text style={{ color: "red" }}>Sai</Text>
                            )}
                          </Col>
                        </Row>
                      </List.Item>
                    ))}
                  </List>
                </div>
              ) : (
                <>
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{ marginBottom: 20 }}
                  >
                    <h3>
                      Kết quả:{" "}
                      <span
                        style={{
                          textAlign: "center",
                          marginTop: 10,
                          fontWeight: 400,
                        }}
                      >
                        {correctCount >= 8
                          ? "Chúc mừng! Bạn đã đạt yêu cầu. Hãy tiếp tục phát huy nhé!"
                          : "Bạn chưa đạt yêu cầu. Hãy ôn tập và thử lại nhé!"}
                      </span>
                    </h3>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        backgroundColor: "#4CAF50",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        fontSize: 18,
                      }}
                    >
                      {correctCount}
                    </div>
                  </Flex>

                  <Row gutter={[16, 16]}>
                    {columns.map((column, colIndex) => (
                      <Col key={colIndex} span={6}>
                        {column.map((word, index) => {
                          const globalIndex = colIndex + index * columns.length; // To maintain the correct word ordering
                          const userAnswer = userAnswers[globalIndex] || "";
                          const isCorrect = userAnswer === word;

                          return (
                            <Card
                              key={globalIndex}
                              style={{
                                marginBottom: "16px",
                                borderRadius: "8px",
                                height: 160,
                                border: isCorrect
                                  ? "1px solid green"
                                  : "1px solid red",
                                boxShadow: isCorrect
                                  ? "0 4px 12px rgba(76, 175, 80, 0.4)"
                                  : "0 4px 12px rgba(244, 67, 54, 0.4)",
                              }}
                            >
                              <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                              >
                                <Space
                                  direction="horizontal"
                                  style={{
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Text strong>Từ {globalIndex + 1}</Text>
                                  <Text
                                    style={{
                                      color: isCorrect ? "green" : "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {isCorrect ? "Đúng" : "Sai"}
                                  </Text>
                                </Space>
                                <p style={{ textAlign: "center" }}>{word}</p>
                                <div style={{ textAlign: "center" }}>
                                  {renderCharacterComparison(word, userAnswer)}
                                </div>
                              </Space>
                            </Card>
                          );
                        })}
                      </Col>
                      
                    ))}
                    
                  </Row>
                  <Button
            type="primary"
            block
            onClick={handleOkData}
            style={{
             
              marginBottom:-20
            }}
          >
            Đóng
          </Button>
                </>
              )}
            </>
          )}
        </Flex>
      </Modal>
    </div>
  );
};

export default GrammarAnswer;
