import React, { useState, useEffect } from "react";
import {
  Radio,
  Button,
  Typography,
  Row,
  Col,
  Alert,
  Modal,
  Flex,
  Select,
} from "antd";
import type { RadioChangeEvent } from "antd/lib/radio";
import { SoundOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useSpeechSynthesis from "../../context/speech";
import Loading from "../../global/item/Loading/loading";
import { useAuth } from "../../context/AuthContext";
import { dispatch, useSelector } from "../../redux/store";
import { getVocabQuiz } from "../../redux/action/vocab";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { getWordform } from "../../redux/action/grammar/wordform";
import { getTopicByUser } from "../../redux/action/topic";
import UseRespon from "../../../hook/useResponsive";
import { openNotification } from "../../global/item/Notification/Notification";

type VocabItem = {
  _id: string;
  name: string;
  meaning: string;
  transcription?: string;
  example?: string;
};

const { Text } = Typography;
const { Option } = Select;

const shuffleArray = <T,>(array: T[]): T[] => {
  const shuffledArray = [...array];
  const n = shuffledArray.length;

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

function SelectAnswer() {
  const { isMobile } = UseRespon();
  const { speakText } = useSpeechSynthesis();
  const [questions, setQuestions] = useState<VocabItem[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [options, setOptions] = useState<string[]>([]);
  const [isIncorrect, setIsIncorrect] = useState<boolean>(false);
  const [incorrectWordDetails, setIncorrectWordDetails] =
    useState<VocabItem | null>(null); // State for incorrect word details
  const { t } = useTranslation();
  const { vocabList } = useSelector((state) => state.vocab);
  const { user } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedValuesTopic, setSelectedValuesTopic] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { topicList } = useSelector((state) => state.topic);
  const { wordformList } = useSelector((state) => state.wordform);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("10"); // State for selected radio button

  useEffect(() => {
    setIsModalVisible(true);
  }, []);
  useEffect(() => {
    dispatch(getWordform({}));
    if (user?._id) {
      dispatch(getTopicByUser(user._id));
    }
  }, [user?._id]);

  useEffect(() => {
    if (vocabList.length > 0) {
      const shuffledQuestions = shuffleArray(vocabList);
      setQuestions(shuffledQuestions);
      setCurrentQuestionIndex(0);
    }
  }, [vocabList]);

  useEffect(() => {
    if (user?._id) {
      dispatch(
        getVocabQuiz({
          userId: user._id,
        })
      );
    }
  }, [user?._id]);

  useEffect(() => {
    const generateOptions = () => {
      if (questions.length > 0 && currentQuestionIndex < questions.length) {
        const currentQuestion = questions[currentQuestionIndex];

        const incorrectOptions = questions
          .filter((q) => q._id !== currentQuestion._id)
          .map((q) => q.meaning);

        const selectedIncorrectOptions = shuffleArray(incorrectOptions).slice(
          0,
          3
        );
        const allOptions = shuffleArray([
          currentQuestion.meaning,
          ...selectedIncorrectOptions,
        ]);

        setOptions(allOptions);
      }
    };
    generateOptions();
  }, [currentQuestionIndex, questions]);

  const handleOptionChange = (e: RadioChangeEvent) => {
    setSelectedOption(e.target.value);
    setIsIncorrect(false);
    setIncorrectWordDetails(null);
  };

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (selectedOption === currentQuestion.meaning) {
      openNotification({
        type: "success",
        message: t("success"),
        description: t("Tuyệt vời! Bạn đã chọn đáp án đúng."),
      });
      setTimeout(() => {
        setSelectedOption(null);
        setIsIncorrect(false);
        setCurrentQuestionIndex((prev) => prev + 1);
        setIncorrectWordDetails(null);
      }, 1000);
    } else {
      setIsIncorrect(true);
      openNotification({
        type: "error",
        message: t("error"),
        description: t("Rất tiếc, đáp án của bạn không chính xác."),
      });
      const incorrectWord = questions.find((q) => q.meaning === selectedOption);
      if (incorrectWord) {
        setIncorrectWordDetails(incorrectWord);
      }
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };
  const handleSelectChangeTopic = (values: string[]) => {
    setSelectedValuesTopic(values);
  };
  const handleRadioChange = (e: any) => {
    setSelectedRadioValue(e.target.value); // Update the radio button value
  };
  if (questions.length === 0) {
    return <Loading />;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const optionsTopic = topicList.map((item: any) => ({
    value: item._id,
    label: item.name,
  }));

  const optionsCategory = wordformList.map((item: any) => ({
    value: item._id,
    label: item.name,
  }));

  return (
    <div>
      <TitleComponent title={t("Kiểm tra từ vựng")} isExist={false} />
      <Flex justify="center" align="center">
        <Row gutter={[30, 0]}>
          <Col xl={15} xs={24}>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Text strong>What is the meaning of the word:</Text>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "10px 0",
                  color: `var(--primary-color)`,
                }}
              >
                {currentQuestion.name}
                <SoundOutlined
                  style={{ cursor: "pointer", marginLeft: 5 }}
                  onClick={() => speakText(currentQuestion.name)}
                />
              </div>

              <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                <Flex align="center" style={{ width: "100%", marginTop: 15 }}>
                  <Row gutter={[20, 10]}>
                    {options.map((option, index) => (
                      <Col xs={24} xl={12} key={index}>
                        <div style={{ display: "block", width: "100%" }}>
                          <Radio
                            value={option}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              display: "flex",
                              alignItems: "center",

                              border:
                                selectedOption === option
                                  ? isIncorrect
                                    ? "2px solid red"
                                    : "2px solid #1890ff"
                                  : "1px solid #d9d9d9",
                              borderRadius: 10,
                              padding: 20,
                              marginBottom: "10px",
                              height: isMobile ? 60 : 100,
                              backgroundColor:
                                selectedOption === option
                                  ? isIncorrect
                                    ? "#ffccc7"
                                    : "#e6f7ff"
                                  : "white",
                            }}
                          >
                            <div
                              style={{
                                overflowY: "auto",
                                maxHeight: isMobile ? 42 : 62,
                                width: "100%",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: option }}
                              />
                            </div>
                          </Radio>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Flex>
              </Radio.Group>

              <Button
                type="primary"
                onClick={handleSubmit}
                size="large"
                style={{ width: "100%", marginTop: "20px" }}
              >
                Kiểm tra
              </Button>
            </div>
          </Col>

          <Col xl={9} xs={24}>
            {isIncorrect && incorrectWordDetails && (
              <Alert
                description={
                  <div
                    style={{
                      height: isMobile ? "auto" : "317px",
                      width: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <Text strong style={{ fontSize: "20px", color: "#d85d5d" }}>
                      Không chính xác
                    </Text>
                    <p style={{ margin: "8px 0" }}>
                      <strong>Name:</strong>{" "}
                      <span style={{ color: "#a90000", fontWeight: 500 }}>
                        {incorrectWordDetails.name}
                        <span>[{incorrectWordDetails.transcription}]</span>
                        <SoundOutlined
                          style={{ cursor: "pointer", marginLeft: 5 }}
                          onClick={() =>
                            speakText(incorrectWordDetails.name)
                          }
                        />
                      </span>
                    </p>
                    <p style={{ margin: "8px 0" }}>
                      <strong>Meaning: </strong>
                      <span
                        style={{ color: "#a40c0c" }}
                        dangerouslySetInnerHTML={{
                          __html: incorrectWordDetails.meaning,
                        }}
                      />
                    </p>
                    {incorrectWordDetails.example && (
                      <p style={{ margin: "8px 0" }}>
                        <strong>Example: </strong>
                        <span
                          style={{ color: "#a40c0c" }}
                          dangerouslySetInnerHTML={{
                            __html: incorrectWordDetails.example,
                          }}
                        />
                        <SoundOutlined
                          style={{ cursor: "pointer", marginLeft: 5 }}
                          onClick={() =>
                            speakText(incorrectWordDetails.example!)
                          }
                        />
                      </p>
                    )}
                  </div>
                }
                type="error"
                closable
                style={{
                  margin: "20px 0",
                  height: "auto", // Keep this auto for alert container
                  borderRadius: "8px",
                }}
              />
            )}
          </Col>
        </Row>
      </Flex>

      <Modal
        title={
          <h3 style={{ color: "#333", fontWeight: 600 }}>
            Thiết lập bài kiểm tra
          </h3>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={600}
        footer={false}
      >
        <Flex vertical>
          <div>
            <h4 style={{ marginBottom: 8, color: "#444", fontWeight: 500 }}>
              Chủ đề
            </h4>
            <Select
              mode="multiple"
              value={selectedValuesTopic}
              placeholder="Chọn chủ đề"
              className="selectFormFilter"
              style={{ width: "100%" }}
              onChange={handleSelectChangeTopic}
            >
              {optionsTopic.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <h4 style={{ marginBottom: 8, color: "#444", fontWeight: 500 }}>
              Từ loại
            </h4>
            <Select
              mode="multiple"
              value={selectedValues}
              placeholder="Chọn từ loại"
              className="selectFormFilter"
              style={{ width: "100%", border: "none" }}
              onChange={handleSelectChange}
            >
              {optionsCategory.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <h4 style={{ marginBottom: 8, color: "#444", fontWeight: 500 }}>
              Số lượng
            </h4>
            <Radio.Group
              defaultValue={selectedRadioValue} // Set the initial value
              onChange={handleRadioChange} // Handle radio change
              style={{ width: "100%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Radio.Button
                  value="10"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="20"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  20
                </Radio.Button>
                <Radio.Button
                  value="30"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  30
                </Radio.Button>
                <Radio.Button
                  value="40"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  40
                </Radio.Button>
              </div>
            </Radio.Group>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 30,
            }}
          >
            <Button style={{ padding: "0 20px" }} onClick={handleCancel}>
              Hủy
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (user?._id) {
                  dispatch(
                    getVocabQuiz({
                      userId: user._id,
                      amount: Number(selectedRadioValue),
                      categoryIds: selectedValues,
                      topicIds: selectedValuesTopic,
                    })
                  );
                }
                setIsModalVisible(false);
              }}
            >
              Tiếp tục
            </Button>
          </div>
        </Flex>
      </Modal>
    </div>
  );
}

export default SelectAnswer;
