import { Flex, Select } from "antd";
import React, { useEffect } from "react";
import Vietnam from "../../../../accset/svg/flag/vietnam";
import United from "../../../../accset/svg/flag/united";
import Korea from "../../../../accset/svg/flag/korea";
import { useLanguage } from "../../../context/Language";
import i18n from "../../../context/translation";

const Translation: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  // Use useEffect to set the initial language based on localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, [language, setLanguage]);

  const handleChangeLanguage = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
    localStorage.setItem("language", value); // Save the selected language to localStorage
  };

  return (
    <Flex align="center">
      <Select
        className="customSelect"
        value={language}
        onChange={handleChangeLanguage}
        options={[
          { value: "vi", label: <Vietnam /> },
          { value: "en", label: <United /> },
          { value: "ko", label: <Korea /> },
        ]}
      />
    </Flex>
  );
};

export default Translation;
