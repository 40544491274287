import React from 'react'
import GlobalAction from '../../../screen/Global'

export default function WordformList() {
  
  return (
    <div>
      <GlobalAction action={false} isTopic={false}/>
      
    </div>
  )
}
