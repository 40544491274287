// src/App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./sass/index.scss";
import { Provider } from "react-redux";
import { store } from "./component/redux/store";
import AppRoutes from "./router/routerApp";
import { AuthProvider } from "./component/context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google"; 
function App() {
  return (
    <Provider store={store}>
        <GoogleOAuthProvider clientId="76359344999-m44q7p1rrtf9ohlcabs1hujfvg2jgj96.apps.googleusercontent.com">
        <AuthProvider>
          <Router>
            <AppRoutes />
          </Router>
        </AuthProvider>
    </GoogleOAuthProvider>
      </Provider>
  );
}

export default App;
