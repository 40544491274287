import React from "react";
import { useTranslation } from "react-i18next";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import WordformForm from "./WordformForm";

function WordformUpdate() {
    const {t} = useTranslation()
  return (
    <div>
      <TitleComponent title={t('update')} isExist={false} />
      <WordformForm isEdit={true}/>
    </div>
  );
}

export default WordformUpdate;
