import { AxiosResponse } from "axios";
import { deleteAsync, getAsync, postAsync, putAsync } from "./client";

class VocabService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/vocab', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getByUserId(
    userId: string,
    pageIndex: number = 1,
    pageSize: number = 12,
    search: string = '',
    categoryIds: string[] = [],
    topicIds: string[] = [],
    isRemember?: boolean | null 
  ): Promise<AxiosResponse> {
    try {
      const params = new URLSearchParams({
        page: pageIndex.toString(),
        limit: pageSize.toString(),
        ...(search && { search })
      });

      if (isRemember !== null && isRemember !== undefined) {
        params.append('isRemember', isRemember.toString());
      }

      // Append each categoryId as a separate query parameter
      categoryIds.forEach((categoryId) => {
        params.append('categoryId', categoryId);
      });

      // Append each topicId as a separate query parameter
      topicIds.forEach((topicId) => {
        params.append('topicId', topicId);
      });

      const query = params.toString();
      const response = await getAsync(`/vocab/user/${userId}?${query}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getByUserIdAndTopic(
    userId: string,
    topicId: string,
    pageIndex: number = 1,
    pageSize: number = 12,
    search: string = '',
    categoryIds: string[] = [],
    isRemember?: boolean | null
  ): Promise<AxiosResponse> {
    try {
      const params = new URLSearchParams({
        page: pageIndex.toString(),
        limit: pageSize.toString(),
        ...(search && { search }),
        topicId // Add topicId as a query parameter
      });
  
      if (isRemember !== null && isRemember !== undefined) {
        params.append('isRemember', isRemember.toString());
      }
  
      if (categoryIds.length > 0) {
        categoryIds.forEach((categoryId) => {
          params.append('categoryId', categoryId);
        });
      }
  
      const query = params.toString();
      const response = await getAsync(`/vocab/user/topic/${userId}?${query}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getByUserIdAndCategory(
    userId: string,
    categoryId:string,
    pageIndex: number = 1,
    pageSize: number = 12,
    search: string = '',
    topicIds: string[] = [],
    isRemember?: boolean | null 
  ): Promise<AxiosResponse> {
    try {
      const params = new URLSearchParams({
        page: pageIndex.toString(),
        limit: pageSize.toString(),
        ...(search && { search }),
        categoryId
      });

      if (isRemember !== null && isRemember !== undefined) {
        params.append('isRemember', isRemember.toString());
      }

      topicIds.forEach((topicId) => {
        params.append('topicId', topicId);
      });

      const query = params.toString();
      const response = await getAsync(`/vocab/user/category/${userId}?${query}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
async getVocabQuiz(
  userId: string,
  { amount = 10, category = [], topic = [] }: { 
    amount?: number; 
    category?: string[]; 
    topic?: string[]; 
  }
): Promise<AxiosResponse> {
  try {
    const params = new URLSearchParams({
      amount: amount.toString(), // Chuyển amount thành chuỗi
    });

    // Thêm mỗi categoryId như một tham số truy vấn riêng biệt
    category.forEach((categoryId) => {
      params.append('category', categoryId);
    });

    // Thêm mỗi topicId như một tham số truy vấn riêng biệt
    topic.forEach((topicId) => {
      params.append('topic', topicId);
    });

    const query = params.toString();
    const response = await getAsync(`/vocab/quiz/${userId}?${query}`);
    return response;
  } catch (error) {
    throw error;
  }
}




  async getPutRememberByUserId(vocabId: string): Promise<AxiosResponse> {
    try {
      const response = await putAsync(`/vocab/remember/${vocabId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getById(id: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/vocab/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }


  async post(formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/vocab', formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async put(id: string,formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await putAsync(`/vocab/${id}`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async delete(_id: string): Promise<AxiosResponse> {
    return await deleteAsync(`/vocab/${_id}`);
  }
}

// Create an instance of VocabService
const vocabServiceInstance = new VocabService();

export default vocabServiceInstance;
