import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatisticalState } from "../../@type/statistical";
import statisticalService from "../../service/statistical";

export const getStatistical = createAsyncThunk(
    "get/getStatistical",
    async (id: any) => {
      const { data } = await statisticalService.getByUser(id);
      return data;
    }
  );
export const getCountVocabByUserId = createAsyncThunk(
    "get/getCountVocabByUserId",
    async ({
      userId,
      isRemember
    }: {
      userId: string;
      isRemember?: boolean | null;
    }) => {
      const { data } = await statisticalService.getCountByUser(userId, isRemember);
      return data; 
    }
  );
  export const getCountRememberByUser = createAsyncThunk(
    "get/getCountRememberByUser",
    async (id: any) => {
      const { data } = await statisticalService.getCountRememberByUser(id);
      return data;
    }
  );
  export const getCountNotRememberByUser = createAsyncThunk(
    "get/getCountNotRememberByUser",
    async (id: any) => {
      const { data } = await statisticalService.getCountNotRememberByUser(id);
      return data;
    }
  );
const initialState: StatisticalState = {
  totalVocab:{
    notRememberedCount:0,
    rememberedCount:0,
    totalCount:0
  },
  countVocab:[],
  countVocabNotRemember:[],
  countVocabRemember:[]
};

const statisticalSlide = createSlice({
  name: "statistical",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getStatistical.fulfilled, (state, action) => {
      state.totalVocab = action.payload.data;
    });
 
    builder.addCase(getCountVocabByUserId.fulfilled, (state, action) => {
      state.countVocab = action.payload.data;
    });
    builder.addCase(getCountRememberByUser.fulfilled, (state, action) => {
      state.countVocabRemember = action.payload.data;
    });
    builder.addCase(getCountNotRememberByUser.fulfilled, (state, action) => {
      state.countVocabNotRemember = action.payload.data;
    });
  },
});

export default statisticalSlide.reducer;
