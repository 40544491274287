import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import wordform from "../../../service/grammar/wordform";
import { WordformState } from "../../../@type/grammar/wordform";

export const getWordform = createAsyncThunk(
  "get/getWordform",
  async (params: any) => {
    const { data } = await wordform.get(params);
    return data;
  }
);
export const createWordform = createAsyncThunk(
    "get/createWordform",
    async (formData: FormData) => {
      const { data } = await wordform.post(formData);
      return data;
    }
  );
  export const getWordformById = createAsyncThunk(
    "get/getWordformById",
    async (id: any) => {
      const { data } = await wordform.getById(id);
      return data;
    }
  );
  export const putWordform = createAsyncThunk(
    "put/putWordform",
    async ({ id, formData }: { id: string; formData: FormData }) => {
      const { data } = await wordform.put(id, formData);
      return data;
    }
  );
const initialState: WordformState = {
  wordformList: [],
  wordformDetail:{
    _id:"",
    image:"",
    name:"",
    symbol:"",
    desc:""
  }

};

const wordformSlice = createSlice({
  name: "wordform",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWordform.fulfilled, (state, action) => {
      state.wordformList = action.payload;
    });
    builder.addCase(getWordform.fulfilled, (state, action) => {
      state.wordformList = action.payload.categorys;
    });
    builder.addCase(getWordformById.fulfilled, (state, action) => {
      state.wordformDetail = action.payload.categorys;
    });
    builder.addCase(putWordform.fulfilled, (state, action) => {
      state.wordformList = action.payload.categorys;
    });
  },
});

export default wordformSlice.reducer;
