import { AxiosResponse } from "axios";
import {  postAsync } from "./client";

class GlobalService {
  
  async post(formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/user/send-email', formData);
      return response;
    } catch (error) {
      throw error; 
    }
  }
  
}

// Create an instance of TopicService
const globalServiceInstance = new GlobalService();

export default globalServiceInstance;
