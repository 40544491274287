import { useEffect, useState, useCallback } from 'react';
import { useLanguage } from './Language';

const useSpeechSynthesis = () => {
  const { language } = useLanguage();
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [voiceSelected, setVoiceSelected] = useState<SpeechSynthesisVoice | null>(null);

  const updateVoices = useCallback(() => {
    const availableVoices = window.speechSynthesis.getVoices();
    setVoices(availableVoices);
  }, []);

  useEffect(() => {
    updateVoices();

    const handleVoicesChanged = () => updateVoices();
    window.speechSynthesis.onvoiceschanged = handleVoicesChanged;

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, [updateVoices]);

  const handleVoiceChange = useCallback((name: string) => {
    const voice = voices.find(v => v.name === name);
    if (voice) {
      setVoiceSelected(voice);
    }
  }, [voices]);

  useEffect(() => {
    const storedVoiceName = localStorage.getItem('selectedEnglishVoice');
    if (storedVoiceName) {
      handleVoiceChange(storedVoiceName);
    }
  }, [handleVoiceChange]); 

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'selectedEnglishVoice') {
        handleVoiceChange(event.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleVoiceChange]);

  const selectVoice = useCallback((voice: SpeechSynthesisVoice) => {
    window.speechSynthesis.cancel();
    handleVoiceChange(voice.name);
    if (language === 'en') {
      localStorage.setItem('selectedEnglishVoice', voice.name);
    }
  }, [language, handleVoiceChange]);

  // Helper function to remove all HTML tags
// Helper function to remove HTML tags and replace non-breaking spaces
const removeHtmlTags = (text: string) => {
  return text
    .replace(/<[^>]*>/g, '') // Removes all HTML tags
    .replace(/&nbsp;/g, ' ') // Replaces non-breaking spaces with regular spaces
    .trim(); // Trim any leading or trailing whitespace
};


  const speakText = useCallback((text: string, options: { rate?: number; pitch?: number; volume?: number } = {}) => {
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance(removeHtmlTags(text)); // Remove HTML tags before speaking
      
      // Nếu ngôn ngữ là tiếng Anh, lấy giọng nói từ localStorage
      if (language === 'en') {
        const storedVoiceName = localStorage.getItem('selectedEnglishVoice');
        const storedVoice = voices.find(v => v.name === storedVoiceName);
        speech.voice = storedVoice || voices[0]; // Sử dụng giọng nói đã lưu, hoặc giọng đầu tiên nếu không tìm thấy
      } else {
        speech.voice = voiceSelected || voices[0]; // Mặc định giọng đầu tiên cho ngôn ngữ khác
      }
  
      speech.lang = language === 'vi' ? 'vi-VN' :
                    language === 'en' ? 'en-US' :
                    language === 'ko' ? 'ko-KR' : 'en-US';
      
      speech.rate = options.rate || 1;
      speech.pitch = options.pitch || 1;
      speech.volume = options.volume || 1;
  
      window.speechSynthesis.speak(speech);
    }
  }, [language, voiceSelected, voices]);
  
  return { speakText, voices, voiceSelected, selectVoice };
};

export default useSpeechSynthesis;
