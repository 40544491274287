import { useEffect } from 'react';
import { dispatch, useSelector } from '../../component/redux/store';
import { getWordform, getWordformById } from '../../component/redux/action/grammar/wordform';

interface UseWordform {
  type: 'list' | 'wordform';
  idWordform?: string | null;
  userId?: string;

}

export const useWordform = ({ type, idWordform,userId }: UseWordform) => {
  const { wordformList, wordformDetail } = useSelector((state) => state.wordform);

  useEffect(() => {
    if (type === 'list') {
      dispatch(getWordform({userId:userId}));
    }
  }, [type,userId]);

  useEffect(() => {
    if (type === 'wordform' && idWordform) {
      dispatch(getWordformById(idWordform));
    }
  }, [type, idWordform]);

  return { wordformDetail, wordformList };
};
