import { Card, Col } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { SoundOutlined } from "@ant-design/icons"; // Import SoundOutlined
import UseRespon from "../../../../../hook/useResponsive";

interface Props {
  title: string;
  desc: string;
  sound?: boolean; 
  handleCollapseChange?: (name: string) => void;
}

const VocabDetailCard: React.FC<Props> = ({ title, desc, sound =false, handleCollapseChange }) => {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();

  return (
    <Col xl={12} xs={24}>
      <Card
        title={<span style={{ color: "#5c9aff" }}>{t(title)}</span>}
        bordered={false}
        style={{
          marginBottom: "16px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          height: isMobile ? 150 : 200,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            maxHeight: "100px",
            overflowY: "auto",
            lineHeight: "1.5em",
            display: "flex", // Use flex to align items
            alignItems: "center", // Center items vertically
          }}
        >
          <div
            style={{
              flex: 1, // Allow this div to grow
              maxHeight: "100px",
              overflowY: "auto",
              lineHeight: "1.5em",
            }}
            dangerouslySetInnerHTML={{ __html: desc }}
          />
          {sound && ( 
            <SoundOutlined
              style={{ cursor: "pointer", marginLeft: "8px" }} 
              onClick={(e) => {
                e.stopPropagation(); 
                if (handleCollapseChange) { 
                  handleCollapseChange(desc); 
                }
              }}
            />
          )}
        </div>
      </Card>
    </Col>
  );
};

export default VocabDetailCard;
