import { Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { useNavigate, useParams } from "react-router-dom";
import { dispatch, useSelector } from "../../../../redux/store";
import { useAuth } from "../../../../context/AuthContext";
import { getTopicByUser } from "../../../../redux/action/topic";
import { createVocab, getVocabById, updateVocab } from "../../../../redux/action/vocab";
import { openNotification } from "../../../../global/item/Notification/Notification";
import GrammarAnswer from "../../../../view/testUser/TestUser";
import QuillEditor from "../../../../global/Custom/Quill/Quill";
import ButtonBottom from "../../../../global/item/ButtonComponent/ButtonBottom";
import { useWordform } from "../../../../../hook/wordform/useWordform";

const { Option } = Select;
type Prop = {
  isEdit?: boolean;
};
function VocabForm({ isEdit = false }: Prop) {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState<Blob | null>(null);
  const [form] = Form.useForm();
  const { topicList } = useSelector((state) => state.topic);
  const { vocabDetail } = useSelector((state) => state.vocab);
  const state = useAuth();
  const user = state.user;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedValuesTopic, setSelectedValuesTopic] = useState<string[]>([]);
  const { wordformList } = useWordform({ type: "list", userId: user?._id });

  useEffect(() => {
    dispatch(getTopicByUser(user?._id));
    dispatch(getVocabById(id));
  }, [user?._id, id]);

  const handleCancel = () => {
    navigate(-1);
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); 
      const formData = new FormData();
  
      formData.append("name", values.name || "");
      formData.append("transcription", values.transcription || "");
      formData.append("meaning", editorContent || "");
      formData.append("synonym", values.synonym || "");
      formData.append("antonym", values.antonym || "");
      formData.append("phrase", values.phrase || "");
      formData.append("context", values.context || "");
      formData.append("example", editorExample || "");
      formData.append("note", editorNote || "");
  
      if (Array.isArray(selectedValues)) {
        selectedValues.forEach((id) => {
          formData.append("category[]", id);
        });
      }
  
      if (Array.isArray(selectedValuesTopic)) {
        selectedValuesTopic.forEach((topicId) => {
          formData.append("topic[]", topicId);
        });
      } else if (values.topic) {
        formData.append("topic[]", values.topic);
      }
  
      if (user?._id) {
        formData.append("user", user._id);
      }
  
      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }
  
      let resultAction;
  
      if (isEdit && id) {
         resultAction = await dispatch(updateVocab({ id: id, formData }));
      } else {
        resultAction = await dispatch(createVocab(formData));
      }

      if (createVocab.fulfilled.match(resultAction) || updateVocab.fulfilled.match(resultAction)) {
        openNotification({
          type: "success",
          message: t("success"),
          description: isEdit ? t("successUpdate") : t("successCreate"), // Use appropriate success message
        });
        setTimeout(() => {
          window.location.reload(); // Adjust the time as necessary
        }, 1000);
      } else {
        // Handle errors
        openNotification({
          type: "error",
          message: t("error"),
          description: t("errorCreate"),
        });
      
      }
    } catch (error) {
      // Handle validation or unexpected errors
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorCreate"),
      });
    }
  };
  

  const handleCloseImg = () => {
    setUploadedImage(null);
  };

  const handleFileChange = (info: any) => {
    const file = info.file.originFileObj;
    if (file instanceof Blob) {
      setUploadedImage(file);
    }
  };
  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };
  const handleSelectChangeTopic = (values: string[]) => {
    setSelectedValuesTopic(values);
  };
  const options = wordformList.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const optionsTopic = topicList.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const [editorContent, setEditorContent] = useState("");
  const [editorExample, setEditorExample] = useState("");
  const [editorNote, setEditorNote] = useState("");
  useEffect(() => {
    if (isEdit && vocabDetail) {
      const categoryIds = vocabDetail.category ? vocabDetail.category.map((cat) => cat._id) : [];
      const topicIds = vocabDetail.topic ? vocabDetail.topic.map((topic) => topic._id) : [];
  
      form.setFieldsValue({
        name: vocabDetail.name || '',
        transcription: vocabDetail.transcription || '',
        synonym: vocabDetail.synonym || '',
        antonym: vocabDetail.antonym || '',
        phrase: vocabDetail.phrase || '',
        context: vocabDetail.context || '',
        meaning: vocabDetail.meaning || '',
        example: vocabDetail.example || '',
        note: vocabDetail.note || '',
        category: categoryIds,
        topic: topicIds,
      });
  
      setEditorContent(vocabDetail.meaning || '');
      setEditorExample(vocabDetail.example || '');
      setEditorNote(vocabDetail.note || '');

      setSelectedValues(categoryIds);
      setSelectedValuesTopic(topicIds);
    }
  }, [isEdit, vocabDetail, form]);
  
  return (
    <>
      {user?.mustTakeTest === true && !isEdit ? <GrammarAnswer /> : <div>
        
        <Form
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            isEdit
              ? {
                  name: vocabDetail?.name,
                  transcription: vocabDetail?.transcription,
                }
              : {}
          }
          autoComplete="off"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Card className="layoutItem">
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              {t("basic_information")}
            </h3>
            <Row gutter={{ xs: 12, xl: 20 }}>
              <Col xl={12} xs={24}>
                <Row gutter={{ xs: 12, xl: 20 }}>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      label={t("vocab")}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t("please_input_your_data"),
                        },
                      ]}
                    >
                      <Input placeholder={t("enter_data_name")} />
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      label={t("transcrip")}
                      name="transcription"
                      rules={[
                        {
                          required: true,
                          message: t("please_input_your_data"),
                        },
                      ]}
                    >
                      <Input placeholder={t("enter_data_name")} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} xs={24}>
                <Row gutter={{ xs: 12, xl: 20 }}>
                  <Col xl={12} xs={12}>
                    <Form.Item
                      label={t("word_type")}
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: t("please_input_your_data"),
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        value={selectedValues}
                        placeholder="Chọn từ loại"
                        className="selectForm"
                        style={{ width: "100%" }}
                        onChange={handleSelectChange}
                      >
                        {options.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={12}>
                    <Form.Item label={t("topic")} name="topic">
                      <Select
                        mode="multiple"
                        value={selectedValuesTopic}
                        placeholder="Chọn chủ đề"
                        className="selectForm"
                        style={{ width: "100%" }}
                        onChange={handleSelectChangeTopic}
                      >
                        {optionsTopic.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xl={24} xs={24}>
                <Form.Item
                  label={t("meaning")}
                  name="meaning"
                  rules={[
                    {
                      required: true,
                      message: t("please_input_your_data"),
                    },
                  ]}
                >
                  <QuillEditor
                    value={editorContent}
                    onChange={setEditorContent}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card className="layoutItem">
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              {t("additional_information")}
            </h3>
            <Row gutter={{ xs: 12, xl: 20 }}>
              <Col xl={12} xs={12}>
                <Form.Item label={t("synonymo")} name="synonym">
                  <Input placeholder={t("enter_data_name")} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item label={t("antonym")} name="antonym">
                  <Input placeholder={t("enter_data_name")} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item label={t("phrase")} name="phrase">
                  <Input placeholder={t("enter_data_name")} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item label={t("context")} name="context">
                  <Input placeholder={t("enter_data_name")} />
                </Form.Item>
              </Col>
              <Col xl={24} xs={24}>
                <Form.Item label={t("example")} name="example">
                  <QuillEditor
                    value={editorExample}
                    onChange={setEditorExample}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card className="layoutItem">
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              {t("additional_information")}
            </h3>
            <Row gutter={[20, 0]}>
              <Col xl={12} xs={24}>
                <Form.Item label={t("note")} name="note">
                  <QuillEditor value={editorNote} onChange={setEditorNote} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                {uploadedImage ? (
                  <Col xl={24}>
                    <div>
                      <CloseOutlined
                        style={{
                          position: "absolute",
                          right: 24,
                          top: 12,
                          zIndex: 999,
                          fontSize: 16,
                        }}
                        className="iconCustom"
                        onClick={handleCloseImg}
                      />
                      <img
                        src={URL.createObjectURL(uploadedImage)}
                        alt={t("uploaded_image")}
                        style={{
                          border: `var(--border-line)`,
                          borderRadius: 10,
                          maxWidth: "100%",
                          padding: 10,
                          background: "rgba(0, 0, 0, 0.02)",
                          position: "relative",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </Col>
                ) : (
                  <Form.Item name="image" label={t("image")}>
                    <Dragger
                      name="file"
                      multiple={false}
                      showUploadList={false}
                      onChange={handleFileChange}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t("upload_text")}</p>
                      <p className="ant-upload-hint">{t("upload_hint")}</p>
                    </Dragger>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Card>
          <ButtonBottom
            onCancelClick={handleCancel}
            btnCancel={t("cancel")}
            btnContinue={t("continue")}
          />
        </Form>
      </div>}
     
    </>
  );
}

export default VocabForm;
