import React from 'react'
import TitleComponent from '../../../../global/Custom/TitleComponent/TitleComponent'
import VocabContainer from '../VocabContainer'
import { useTranslation } from 'react-i18next'

export default function VocabNotRemember() {
  const {t} = useTranslation()
  return (
    <div>
      <TitleComponent
        title={t("Từ vựng chưa nhớ")}
        type="primary"
        isExist={false}
      />
      <VocabContainer isRemember={false} />
    </div>
  )
}
