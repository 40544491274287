import Lottie from "lottie-react";
import animation from "../../../accset/json/voice.json";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";
import useSpeechSynthesis from "../../context/speech";
import { useEffect, useRef, useState } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { Button, Card, Col, Flex, Input, Popover, Row, Slider } from "antd";
import { openNotification } from "../../global/item/Notification/Notification";
import { CheckOutlined, BookOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/AuthContext";
import { getVocabQuiz } from "../../redux/action/vocab";

const levenshtein = (a: string, b: string): number => {
  const alen = a.length;
  const blen = b.length;
  const dp: number[][] = [];
  let tmp: number;

  if (alen === 0) {
    return blen;
  }
  if (blen === 0) {
    return alen;
  }

  for (let i = 0; i <= alen; i++) {
    dp[i] = [i];
  }
  for (let j = 0; j <= blen; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= alen; i++) {
    for (let j = 1; j <= blen; j++) {
      tmp = a.charAt(i - 1) === b.charAt(j - 1) ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1, // Deletion
        dp[i][j - 1] + 1, // Insertion
        dp[i - 1][j - 1] + tmp // Substitution
      );
    }
  }

  return dp[alen][blen];
};

const similarityPercentage = (a: string, b: string): number => {
  const maxLen = Math.max(a.length, b.length);
  const distance = levenshtein(a, b);
  return ((maxLen - distance) / maxLen) * 100;
};

function ListenTest() {
  const { t } = useTranslation();
  const { speakText } = useSpeechSynthesis();
  const state = useAuth();
  const user = state.user;
  const { vocabList } = useSelector((state) => state.vocab);

  const [examples, setExamples] = useState<string[]>([]);
  const [currentExample, setCurrentExample] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [showScript, setShowScript] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showKey, setShowKey] = useState(false);

  const [rate, setRate] = useState(0.7); // State for rate
  const [pitch, setPitch] = useState(1.1); // State for pitch
  const [volume, setVolume] = useState(0.9);
  const settingsRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node)
    ) {
      setShowSetting(false);
    }
  };
  useEffect(() => {
    if (showSetting) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSetting]);
  useEffect(() => {
    if (user?._id) {
      dispatch(getVocabQuiz({ userId: user._id, amount: 20 }));
    }
  }, [user?._id]);

  useEffect(() => {
    if (vocabList && vocabList.length > 0) {
      const extractedExamples = vocabList
        .filter((vocab) => vocab.example)
        .map((vocab) => vocab.example);
      setExamples(extractedExamples);
      selectRandomExample(extractedExamples);
    }
  }, [vocabList]);
  const stripHTML = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const selectRandomExample = (examplesList: string[]) => {
    if (examplesList.length > 0) {
      const randomIdx = Math.floor(Math.random() * examplesList.length);
      setCurrentExample(examplesList[randomIdx]);
    }
  };

  const handleCollapseChange = (text: any) => {
    if ("speechSynthesis" in window) {
      speakText(text, { rate, pitch, volume });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(e.target.value);
  };

  const handleCheckAnswer = () => {
    const strippedExample = stripHTML(currentExample);
    const strippedUserInput = stripHTML(userInput);

    const similarityPercent = similarityPercentage(
      strippedExample,
      strippedUserInput
    );
    setShowKey(true);
    if (similarityPercent >= 80) {
      openNotification({
        type: "success",
        message: t("success"),
        description: (
          <div>
            <span style={{ color: "green" }}>
              {`${similarityPercent.toFixed(0)}% `}
            </span>
            phù hợp.
          </div>
        ),
      });

      setShowScript(false);
      setIsAnswerCorrect(true);
    } else {
      openNotification({
        type: "error",
        message: t("error"),
        description: (
          <div>
            <span style={{ color: "red" }}>
              {`${similarityPercent.toFixed(0)}% `}
            </span>
            phù hợp. Hãy thử lại.
          </div>
        ),
      });
      setIsAnswerCorrect(false);
    }
  };
  const highlightDifferences = (correct: string, user: string) => {
    const correctWords = correct.split("");
    const userWords = user.split("");
    return correctWords.map((char, index) => {
      return (
        <span
          key={index}
          style={{ color: char === userWords[index] ? "black" : "red" }}
        >
          {char}
        </span>
      );
    });
  };

  const handleShowScript = () => {
    if (currentExample) {
      setShowScript(true);
    }
  };

  const handleNextExample = () => {
    setShowKey(false);
    setUserInput("");
    if (isAnswerCorrect) {
      const updatedExamples = examples.filter(
        (example) => example !== currentExample
      );
      if (updatedExamples.length > 0) {
        selectRandomExample(updatedExamples);
        setExamples(updatedExamples);
      } else {
        alert("Congratulations! You've completed all examples.");
        setCurrentExample("");
      }
      setIsAnswerCorrect(false);
    } else {
      alert("Please answer the current example correctly before moving on.");
    }
  };

  const content = (
    <div>
      <p style={{ padding: "5px 8px", color: `var(--primary-color)` }}>
        Ấn vào để được nghe lại
      </p>
    </div>
  );
  const stripHTMLKey = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    // Lấy văn bản thuần túy
    let text = doc.body.textContent || "";
    // Loại bỏ dấu câu
    return text.replace(/[.,]/g, "").trim();
  };

  return (
    <div>
      <TitleComponent
        title={t("Kiểm tra từ vựng")}
        isExist={false}
        isExtra
        handleExtra={() => setShowSetting(true)}
        type="primary"
      />
      <Popover content={content}>
        <Lottie
          animationData={animation}
          style={{ width: 350, cursor: "pointer", margin: "0 auto" }}
          onClick={(e: any) => {
            // e.stopPropagation();
            const textToRead = stripHTML(currentExample); // Strip out HTML tags and extract text
            handleCollapseChange(textToRead);
          }}
        />
      </Popover>
      {showSetting && (
        <div ref={settingsRef} style={{ margin: "20px 0" }}>
          <div>
            <label>Rate: {rate}</label>
            <Slider
              value={rate}
              min={0.1}
              max={2.0}
              step={0.1}
              onChange={(newValue) => setRate(newValue)}
              tooltipVisible
            />
          </div>
          <div>
            <label>Pitch: {pitch}</label>
            <Slider
              value={pitch}
              min={0.1}
              max={2.0}
              step={0.1}
              onChange={(newValue) => setPitch(newValue)}
              tooltipVisible
            />
          </div>
          <div>
            <label>Volume: {volume}</label>
            <Slider
              value={volume}
              min={0}
              max={1.0}
              step={0.1}
              onChange={(newValue) => setVolume(newValue)}
              tooltipVisible
            />
          </div>
        </div>
      )}

      <Row gutter={[20, 20]} style={{ marginTop: 30 }}>
        <Col xl={12} xs={24} style={{ maxHeight: 320 }}>
          <Card style={{ height: "100%" }}>
            <h4 style={{ margin: 0, color: `var(--primary-color)` }}>
              Câu trả lời của bạn
            </h4>
            <Input.TextArea
              placeholder="Nhập từ bạn nghe được"
              style={{
                height: 80,
                maxHeight: 80,
                minHeight: 80,
                margin: "15px 0",
              }}
              value={userInput}
              onChange={handleInputChange}
            />
            {showScript && currentExample && (
              <h4
                style={{
                  margin: 0,
                  color: `var(--primary-color)`,
                  marginBottom: 15,
                }}
              >
                Script:
                <span
                  color="#000"
                  style={{ fontWeight: 400, paddingLeft: 4 }}
                  dangerouslySetInnerHTML={{ __html: currentExample }}
                />
              </h4>
            )}

            <Flex justify="center" gap={20}>
              <Button type="primary" onClick={handleCheckAnswer}>
                <CheckOutlined />
                Kiểm tra
              </Button>
              <Button type="dashed" onClick={handleShowScript}>
                <BookOutlined />
                Hiện Script
              </Button>
              {isAnswerCorrect && (
                <Button type="primary" onClick={handleNextExample}>
                  Tiếp tục
                </Button>
              )}
            </Flex>
          </Card>
        </Col>
        <Col xl={12} xs={24}>
          <Card style={{ height: "100%" }}>
            <div>
              <h4 style={{ margin: 0, color: `var(--primary-color)` }}>
                Đáp án
              </h4>
              {showKey && isAnswerCorrect !== null && (
                <Card style={{marginTop:15,marginBottom:15, minHeight:80}}>
                  <div style={{ fontWeight: "400" }}>
                    {highlightDifferences(
                      stripHTMLKey(currentExample),
                      userInput
                    )}
                  </div>
                </Card>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ListenTest;
