import { Button, Collapse, Flex } from "antd";
import React, { useEffect, useState } from "react";
import CustomPagination from "../pagination-custom/PaginationCustom";
import { DownloadOutlined } from "@ant-design/icons";
import { useAuth } from "../../../context/AuthContext";
import { dispatch } from "../../../redux/store";
import {
  getVocabByUserId,
  putRememberByUserId,
} from "../../../redux/action/vocab";
import HeartNoactive from "../../../../accset/svg/heart/HeartNoactive";
import { Link } from "react-router-dom";

type Item = {
  name: string;
  transcription: string;
  meaning: string;
  _id: string;
  example: string;
  isRemember: boolean;
};

type Props = {
  data: Item[];
  totalPage?: number;
  handleCollapse: (text: string) => void;
  showModalDetail: (id: string) => void;
  showModalDelete: (id: string) => void;

  onChangePage: (pageIndex: number, pageSize: number) => void;
  exportOnClick?: () => void;
  pageIndex: number;
  pageSize: number;
};

function CollapseComponent({
  data,
  handleCollapse,
  totalPage,
  onChangePage,
  exportOnClick,
  pageIndex,
  pageSize,
  showModalDetail,
  showModalDelete
}: Props) {
  const state = useAuth();
  const userId = state.user?._id;
  const { Panel } = Collapse;
  const [activeHearts, setActiveHearts] = useState<Set<string>>(new Set());
  const [params, setParams] = useState({
    pageSize: pageSize,
    pageIndex: pageIndex,
  });
  useEffect(() => {
    if (Array.isArray(data)) {
      const initialActiveHearts = new Set<string>(
        data.filter((item) => item.isRemember).map((item) => item._id)
      );
      setActiveHearts(initialActiveHearts);
    } else {
      // Handle the case where data is not an array (optional)
      setActiveHearts(new Set<string>());
    }
  }, [data]);
  
  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      pageIndex: pageIndex,
    }));
  }, [pageIndex]);

  const handlePageChange = (newPageIndex: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: newPageIndex,
    }));
    onChangePage(newPageIndex, params.pageSize);
  };

  const handleHeartClick = async (id: string) => {
    setActiveHearts((prev) => {
      const updatedHearts = new Set(prev);
      if (updatedHearts.has(id)) {
        updatedHearts.delete(id);
      } else {
        updatedHearts.add(id);
      }
      return updatedHearts;
    });

    await dispatch(putRememberByUserId(id));
    if (userId) {
      await dispatch(
        getVocabByUserId({
          userId,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
        })
      );
    }
  };

  const handleCollapseChange = (id: string) => {
    const vocabItem = data.find((item) => item._id === id);
    if (vocabItem) {
      handleCollapse(vocabItem.name); // Call handleCollapse to read the vocab name
    }
  };

  const items = Array.isArray(data) && data.length > 0
  ? data.map((vocab) => ({
      _id: vocab._id,
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span style={{ marginRight: "8px" }}>
            {`${vocab.name} [${vocab.transcription}]`}
          </span>
          <div
            onClick={(e) => {
              e.stopPropagation(); // Prevent the collapse from triggering
              handleHeartClick(vocab._id);
            }}
          >
            <HeartNoactive isActive={activeHearts.has(vocab._id)} />
          </div>
        </div>
      ),
      children: (
        <div>
          <p style={{ margin: "0 0 10px 0" }}>
            <strong>Nghĩa của từ:</strong>
            <span dangerouslySetInnerHTML={{ __html: vocab.meaning }} />
          </p>
          {vocab.example && (
            <p style={{ margin: "0 0 10px 0" }}>
              <strong>Example:</strong>
              <span dangerouslySetInnerHTML={{ __html: vocab.example }} />
            </p>
          )}

          <Flex justify="space-between" style={{ marginTop: 5 }}>
            <Flex gap={10}>
              <Button type="primary" onClick={() => showModalDetail(vocab._id)}>
                Chi tiết
              </Button>
              <Link
                to={`/Vocab/Edit/${vocab._id}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                Chỉnh sửa
              </Link>
            </Flex>

            <Button type="text" onClick={() => showModalDelete(vocab._id)}>
              Xóa từ
            </Button>
          </Flex>
        </div>
      ),
      textToRead: vocab.name,
    }))
  : []; // Or handle empty case as needed (e.g., display a message or empty state)

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Button type="text" icon={<DownloadOutlined />} onClick={exportOnClick}>
          Xuất file
        </Button>
        <Flex justify="flex-end" style={{ margin: "10px 0" }}>
          <div className="table__footer__col">
            <CustomPagination
              current={params.pageIndex}
              total={totalPage}
              pageSize={params.pageSize}
              onChange={handlePageChange}
            />
          </div>
        </Flex>
      </Flex>
      <div style={{ maxHeight: "59vh", overflow: "auto" }}>
        {items.map((item) => (
          <Collapse
            key={item._id}
            onChange={() => handleCollapseChange(item._id)}
            style={{
              background: "none",
              border: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Panel header={item.label} key={item._id}>
              {item.children}
            </Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
}

export default CollapseComponent;
