import { useEffect } from 'react';
import { getTopicById, getTopicByUser } from '../../component/redux/action/topic';
import { dispatch, useSelector } from '../../component/redux/store';

interface UseTopicProps {
  type: 'user' | 'topic';
  userId?: string;
  idTopic?: string | null;
}

export const useTopic = ({ type, userId, idTopic }: UseTopicProps) => {
  const { topicList, topicDetail, topicCount } = useSelector((state) => state.topic);

  useEffect(() => {
    if (type === 'user' && userId) {
      dispatch(getTopicByUser(userId));
    }
  }, [type, userId]);

  useEffect(() => {
    if (type === 'topic' && idTopic) {
      dispatch(getTopicById(idTopic));
    }
  }, [type, idTopic]);

  return { topicList, topicDetail,topicCount };
};
