// QuillEditor.tsx

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import CSS mặc định

interface QuillEditorProps {
  value: string;
  onChange: (content: string) => void;
  placeholder?: string;
  readOnly?: boolean;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ value, onChange, placeholder, readOnly }) => {
  return (
    <div className="quill-editor-container">
      <ReactQuill
    
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        modules={{
          toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            ['clean']
          ],
        }}
        formats={[
          'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'link', 'image'
        ]}
      />
    </div>
  );
};

export default QuillEditor;
