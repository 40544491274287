import React, { useState } from 'react';

type HeartNoactiveProps = {
  isActive: boolean; // Prop to indicate if the heart is active
};

function HeartNoactive({ isActive }: HeartNoactiveProps) {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleClick = () => {
    setIsZoomed(true);
    setTimeout(() => setIsZoomed(false), 300); // Reset zoom after animation
  };

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        transform: isZoomed ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.3s ease',
      }}
    >
      {isActive ? (
       <svg width="2rem" height="2rem" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
       <circle cx="52" cy="52" r="51.5" fill="white" stroke="#E9E9F2" />
       <path
         d="M51.9967 31.4691C54.8469 28.5986 58.5246 27.5763 62.0517 27.6273C70.5291 27.75 79.0852 34.6679 79.0852 44.6895C79.0852 54.3293 72.9196 62.3546 66.8214 67.8107C63.7359 70.5713 60.5712 72.7629 58.0012 74.2723C56.717 75.0265 55.5594 75.6231 54.6149 76.0387C54.1444 76.2457 53.7027 76.4184 53.3094 76.5431C52.9672 76.6517 52.4849 76.7831 52.0011 76.7831C51.5174 76.7831 51.035 76.6517 50.6928 76.5431C50.2995 76.4184 49.8579 76.2457 49.3873 76.0387C48.4428 75.6231 47.2853 75.0265 46.0011 74.2723C43.431 72.7629 40.2664 70.5713 37.1808 67.8107C31.0826 62.3546 24.917 54.3293 24.917 44.6895C24.917 34.6916 33.4533 27.6271 41.98 27.6271C45.4114 27.6271 49.1244 28.6042 51.9967 31.4691ZM61.9929 31.6894C58.7489 31.6424 55.7258 32.7437 53.6912 35.7955C53.3145 36.3606 52.6803 36.7 52.0011 36.7C51.322 36.7 50.6878 36.3606 50.311 35.7955C48.2765 32.7437 45.2534 31.6424 41.9993 31.6894C36.999 31.7577 34.0643 34.5637 34.0643 38.546C34.0643 42.1525 37.4947 46.4828 41.6857 49.7071C43.2565 51.1642 44.9827 52.1893 46.0011 53.3092C46.9123 54.1342 47.7494 54.9245 48.5011 55.6244C49.0953 56.1607 49.5817 56.4944 50.0424 56.5C51.8292 56.5001 54.0434 55.4919 56.0011 53.5156C57.9569 51.5467 59.9918 48.9918 61.0044 46.1976C63.1401 44.8687 64.9647 42.6653 64.9647 38.5456C64.9647 34.6084 61.0784 31.5536 61.9929 31.6894Z"
         fill="#F74440"
       />
     </svg>
      ) : (
        <svg width="2rem" height="2rem" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="52" cy="52" r="51.5" fill="white" stroke="#E9E9F2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M51.9967 31.4691C54.8469 28.5986 58.5246 27.5763 62.0517 27.6273C70.5291 27.75 79.0852 34.6679 79.0852 44.6895C79.0852 54.3293 72.9196 62.3546 66.8214 67.8107C63.7359 70.5713 60.5712 72.7629 58.0012 74.2723C56.717 75.0265 55.5594 75.6231 54.6149 76.0387C54.1444 76.2457 53.7027 76.4184 53.3094 76.5431C52.9672 76.6517 52.4849 76.7831 52.0011 76.7831C51.5174 76.7831 51.035 76.6517 50.6928 76.5431C50.2995 76.4184 49.8579 76.2457 49.3873 76.0387C48.4428 75.6231 47.2853 75.0265 46.0011 74.2723C43.431 72.7629 40.2664 70.5713 37.1808 67.8107C31.0826 62.3546 24.917 54.3293 24.917 44.6895C24.917 34.6916 33.4533 27.6271 41.98 27.6271C45.4114 27.6271 49.1244 28.6042 51.9967 31.4691ZM61.9929 31.6894C58.7489 31.6424 55.7258 32.7437 53.6912 35.7955C53.3145 36.3606 52.6803 36.7 52.0011 36.7C51.322 36.7 50.6878 36.3606 50.311 35.7955C48.2887 32.7622 45.2107 31.6896 41.98 31.6896C35.4749 31.6896 28.9795 37.1511 28.9795 44.6895C28.9795 52.5863 34.0877 59.5921 39.8896 64.7831C42.7541 67.346 45.6961 69.3819 48.0583 70.7692C49.2403 71.4633 50.2553 71.9822 51.0234 72.3202C51.4091 72.4899 51.7088 72.6034 51.921 72.6707C51.9513 72.6803 51.9779 72.6884 52.0011 72.6951C52.0243 72.6884 52.051 72.6803 52.0813 72.6707C52.2935 72.6034 52.5932 72.4899 52.9789 72.3202C53.747 71.9822 54.762 71.4633 55.9439 70.7692C58.3061 69.3819 61.2481 67.346 64.1126 64.7831C69.9146 59.5921 75.0227 52.5863 75.0227 44.6895C75.0227 37.1748 68.5472 31.7842 61.9929 31.6894Z" fill="#040509"/>
        </svg>
        
      )}
    </div>
  );
}

export default HeartNoactive;
