import { useState } from "react";
import { useAuth } from "../../component/context/AuthContext";
import { useTopic } from "../../hook/topic/useTopic";
import { useWordform } from "../../hook/wordform/useWordform";
import useSpeechSynthesis from "../../component/context/speech";
import { dispatch } from "../../component/redux/store";
import { deleteVocab, getVocabByUserId, putRememberByUserId } from "../../component/redux/action/vocab";
import { openNotification } from "../../component/global/item/Notification/Notification";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useWordformVocab } from "../../hook/wordform/useWordformVocab";

const useWordformVocabVocabContainer = (categoryId?: string) => {
  const state = useAuth();
  const user = state.user;
  const {t} = useTranslation()
  const { speakText } = useSpeechSynthesis();
  const [params, setParams] = useState({ pageIndex: 1, pageSize: 12 });
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedValuesTopic, setSelectedValuesTopic] = useState<string[]>([]);
  const [showTable, setShowTable] = useState(true);
  const [selectedIdDetail, setSelectedIdDetail] = useState<string | null>(null);
  const [selectedIdDelete, setSelectedIdDelete] = useState<string | null>(null);

  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  

  const { vocabCategoryList, vocabDetail,totalVocabCategory} = useWordformVocab(
    searchValue,
    selectedValuesTopic,
    user?._id,
    selectedIdDetail,
    params.pageIndex,
    params.pageSize,
    categoryId
  );
  
  const { topicList } = useTopic({ type: 'user', userId:user?._id });
  const { wordformList } = useWordform({type:"list"});

  const optionsTopic = topicList.map((data) => ({
    value: data._id,
    label: data.name,
  }));

  const optionsWordform = wordformList.map((data) => ({
    value: data._id,
    label: data.name,
  }));

  const handleExcel = async () => {
    if (user?._id) {
      try {
    
        const response = await axios.post(
          "https://vivpepifood.click/vocab/export-excel",
          { userId: user._id },
          { responseType: "arraybuffer" } 
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Từ vựng.xlsx");
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url); 
      } catch (error) {
      }
    }
  };

  const onChangePage = (pageIndex: number, pageSize: number) => {
    setParams({ pageIndex, pageSize });
  };

  const showModalDetail = (id: string) => {
    setSelectedIdDetail(id);
    setIsModalDetailOpen(true);
  };
  const showModalDelete = (id: string) => {
    setSelectedIdDelete(id);
    setIsModalDelete(true);
  };
  const handleCancelDetail = () => {
    setSelectedIdDetail(null);
    setIsModalDetailOpen(false);
  };
  const handleCancelDelete = () => {
    setSelectedIdDelete(null);
    setIsModalDelete(false);
  };
  const handleCollapseChange = (key: string) => {
    if ("speechSynthesis" in window) {
      speakText(key);
    } else {
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const dataTable = Array.isArray(vocabCategoryList)
  ? vocabCategoryList.map((item) => {
      const categoryNames = Array.isArray(item.category)
        ? item.category.map((category) => category.name).join(", ")
        : "";

      return {
        key: item._id,
        name: item.name,
        wordform: categoryNames,
        transcription: item.transcription,
        meaning: item.meaning,
        isRemember: item.isRemember,
        example: item.example,
      };
    })
  : [];
  
  const handleSwitchChange = async (checked: boolean, key: string) => {
   
    await dispatch(putRememberByUserId(key));
    
    const userId = user?._id;
    if (userId) {
 
      await dispatch(
        getVocabByUserId({
          userId,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          search: searchValue || "", 
          topicIds: selectedValuesTopic.length > 0 ? selectedValuesTopic : [],
          categoryIds: selectedValues.length > 0 ? selectedValues : [],
        })
      );
    } else {
    }
  };
  
  const handleOkDelete = async () => {
    try {
      if (selectedIdDelete) {
        await dispatch(deleteVocab(selectedIdDelete));
        openNotification({
          type: "success",
          message: t("success"),
        });
      }
      setSelectedIdDelete(null);
    } catch (error) {
      openNotification({
        type: "error",
        message: t("error"),
      });
    } finally {
      setIsModalDelete(false);
    }
  };

  return {
    vocabCategoryList,
    vocabDetail,
    totalVocabCategory,
    params,
    searchValue,
    selectedValues,
    selectedValuesTopic,
    showTable,
    optionsTopic,
    optionsWordform,
    isModalDetailOpen,
    isModalDelete,
    setSearchValue,
    setSelectedValues,
    setSelectedValuesTopic,
    setShowTable,
    handleExcel,
    onChangePage,
    showModalDetail,
    handleCancelDetail,
    handleCancelDelete,
    handleCollapseChange,
    handleInputChange,
    handleSwitchChange,
    dataTable,
    showModalDelete,
    handleOkDelete
  };
};

export default useWordformVocabVocabContainer;
