import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Input,
  Menu,
  MenuProps,
  Segmented,
  Space,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { CiViewList } from "react-icons/ci";
import { MdGrid3X3 } from "react-icons/md";
import { TbPackageExport } from "react-icons/tb";
import { useTranslation } from "react-i18next";
interface VocabSearchProps {
  searchValue: string;
  selectedValues: string[];
  selectedValuesTopic: string[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (values: string[]) => void;
  handleSelectChangeTopic: (values: string[]) => void;
  setShowTable: (show: boolean) => void;
  showTable: boolean;
  optionsWordform: { value: string; label: string }[];
  optionsTopic: { value: string; label: string }[];
  isMobile: boolean;
  handleExport: () => void;
}

const VocabSearch: React.FC<VocabSearchProps> = ({
  searchValue,
  selectedValues,
  selectedValuesTopic,
  handleInputChange,
  handleSelectChange,
  handleSelectChangeTopic,
  setShowTable,
  optionsWordform,
  optionsTopic,
  isMobile,
  handleExport,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visibleTopic, setVisibleTopic] = useState(false);

  const [checkedTopics, setCheckedTopics] =
    useState<string[]>(selectedValuesTopic);
  const [checkedCategory, setCheckedCategory] =
    useState<string[]>(selectedValues);
  const onCheckboxChange = (value: string) => {
    const updatedCheckedTopics = checkedTopics.includes(value)
      ? checkedTopics.filter((item) => item !== value)
      : [...checkedTopics, value];
    setCheckedTopics(updatedCheckedTopics);
    handleSelectChangeTopic(updatedCheckedTopics);
  };
  const onCheckboxChangeCategory = (value: string) => {
    const updatedCheckedCategory = checkedCategory.includes(value)
      ? checkedCategory.filter((item) => item !== value)
      : [...checkedCategory, value];
    setCheckedCategory(updatedCheckedCategory);
    handleSelectChange(updatedCheckedCategory);
  };

  const topicItems: MenuProps["items"] = optionsTopic.map((item) => ({
    key: item.value,
    label: (
      <Checkbox
        checked={checkedTopics.includes(item.value)}
        onChange={() => onCheckboxChange(item.value)}
      >
        {item.label}
      </Checkbox>
    ),
  }));

  const categoryItem: MenuProps["items"] = optionsWordform.map((item) => ({
    key: item.value,
    label: (
      <Checkbox
        checked={checkedCategory.includes(item.value)}
        onChange={() => onCheckboxChangeCategory(item.value)}
      >
        {item.label}
      </Checkbox>
    ),
  }));

  return (
    <div>

      <Flex justify="space-between">
        <Flex gap={20} style={{width:"100%"}}>
          <Input
            placeholder={t("searchItem")}
            value={searchValue}
            onChange={handleInputChange}
            style={{ width:isMobile?"100%":300, height: 40 }}
          />
          {!isMobile && <Flex gap={20}>
          <Dropdown
            overlay={<Menu items={categoryItem} />}
            trigger={["hover"]}
            visible={visible}
            onVisibleChange={setVisible}
          >
            <Space>
            {t("wordform")}
              <DownOutlined />
            </Space>
          </Dropdown>
          <Dropdown
            overlay={<Menu items={topicItems} style={{
              maxHeight: '200px', 
              overflowY: 'auto',  
            }}/>}
            trigger={["hover"]}
            visible={visibleTopic}
            onVisibleChange={setVisibleTopic}
            
          >
            <Space>
            {t("topic")}
              <DownOutlined />
            </Space>
          </Dropdown>
          </Flex>}
          
          
        </Flex>
        {!isMobile && <Flex gap={10}>
          <Tooltip title={t("export")}>
            <Button
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                borderRadius: 9,
              }}
              onClick={handleExport}
            >
              <TbPackageExport size={18} />
            </Button>
          </Tooltip>

          <Segmented
            options={[
              {
                value: "List",
                icon: (
                  <Tooltip title={t("list")}>
                    <CiViewList size={18} />
                  </Tooltip>
                ),
              },
              {
                value: "Kanban",
                icon: (
                  <Tooltip title={t("card")}>
                    <MdGrid3X3 size={18} />
                  </Tooltip>
                ),
              },
            ]}
            onChange={(value) => {
              if (value === "List") {
                setShowTable(true);
              } else {
                setShowTable(false);
              }
            }}
            style={{
              height: "100%",
            }}
            className="segmentedCustom"
          />
        </Flex>}
       
      </Flex>
    </div>
  );
};

export default VocabSearch;
