import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { clearData, getVocabById, getVocabByUserId } from '../../component/redux/action/vocab';
import { dispatch, useSelector } from '../../component/redux/store';

export const useVocab = (
  searchValue: string,
  selectedValues: string[],
  selectedValuesTopic: string[],
  userId: string | undefined,
  selectedIdDetail: string | null,
  pageIndex: number,
  pageSize: number,
  isRemember?: boolean
) => {
  const { vocabList, vocabDetail, totalVocab } = useSelector((state) => state.vocab);
  const debouncedSearchRef = useRef<ReturnType<typeof debounce> | null>(null);



  useEffect(() => {
    debouncedSearchRef.current = debounce(
      (userId: string, search: string, categoryIds: string[], topicIds: string[], pageIndex: number, pageSize: number, isRemember?: boolean) => {
        if (userId) {
          // If isRemember is defined, call the respective API
          if (isRemember !== undefined && isRemember !== null) {
            dispatch(clearData());
            dispatch(getVocabByUserId({ userId, search, categoryIds, topicIds, pageIndex, pageSize, isRemember }));
          } else {
            // If isRemember is undefined or null, call the API without isRemember
            dispatch(clearData());
            dispatch(getVocabByUserId({ userId, search, categoryIds, topicIds, pageIndex, pageSize }));
          }
        }
      },
      500 // Debounce time
    );

    // Cleanup debounce when component unmounts
    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, []);

  useEffect(() => {
    if (isRemember === true) {
      debouncedSearchRef.current?.(userId, searchValue, selectedValues, selectedValuesTopic, pageIndex, pageSize, isRemember);
    } 
    else if (isRemember === false) {
      debouncedSearchRef.current?.(userId, searchValue, selectedValues, selectedValuesTopic, pageIndex, pageSize, isRemember);
    } else {
      debouncedSearchRef.current?.(userId, searchValue, selectedValues, selectedValuesTopic, pageIndex, pageSize);
    }
  }, [userId, searchValue, selectedValues, selectedValuesTopic, pageIndex, pageSize, isRemember]);

  useEffect(() => {
    if (selectedIdDetail) {
      dispatch(getVocabById(selectedIdDetail));
    }
  }, [selectedIdDetail]);

  return { vocabList, vocabDetail, totalVocab };
};
