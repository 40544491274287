import { AxiosResponse } from "axios";
import { getAsync, } from "./client";

class StatisticalService {

    async getByUser(userId: string): Promise<AxiosResponse> {
        try {
          const response = await getAsync(`/vocab/total/${userId}`);
          return response;
        } catch (error) {
          throw error; 
        }
      }

      async getCountByUser(
        userId: string,
        isRemember?: boolean | null 
      ): Promise<AxiosResponse> {
        try {
          const params = new URLSearchParams();
    
          if (isRemember !== null && isRemember !== undefined) {
            params.append('isRemember', isRemember.toString());
          }
          const query = params.toString();
          const response = await getAsync(`/vocab/stats/${userId}?${query}`);
          return response;
        } catch (error) {
          throw error;
        }
      }
      async getCountRememberByUser(userId: string): Promise<AxiosResponse> {
        try {
          const response = await getAsync(`/vocab/statsRemember/${userId}`);
          return response;
        } catch (error) {
          throw error; 
        }
      }
      async getCountNotRememberByUser(userId: string): Promise<AxiosResponse> {
        try {
          const response = await getAsync(`/vocab/statsUnremember/${userId}`);
          return response;
        } catch (error) {
          throw error; 
        }
      }
}

const statisticalService = new StatisticalService();

export default statisticalService;
