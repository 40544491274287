import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import topic from "../../service/topic";
import { TopicState } from "../../@type/topic";




export const getTopic = createAsyncThunk(
  "get/getTopic",
  async (params: any) => {
    const { data } = await topic.get(params);
    return data;
  }
);
export const createTopic = createAsyncThunk(
  "get/createWTopic",
  async (formData: FormData) => {
    const { data } = await topic.post(formData);
    return data;
  }
);
export const getTopicById = createAsyncThunk(
  "get/getTopicById",
  async (id: any) => {
    const { data } = await topic.getById(id);
    return data;
  }
);
export const getTopicByUser = createAsyncThunk(
  "get/getTopicByUser",
  async (userId: any) => {
    const { data } = await topic.getByUser(userId);
    return data;
  }
);


const initialState: TopicState = {
  topicList: [],
  topicDetail: {
    _id: "",
    image: "",
    name: "",
    user: "",
    createDate:"",
    vocabCount:0,
  },
  topicCount:0,
};

const wordformSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTopic.fulfilled, (state, action) => {
      state.topicList = action.payload;
    });
    builder.addCase(getTopic.fulfilled, (state, action) => {

      state.topicList = action.payload.topics;

    });
    builder.addCase(getTopicById.fulfilled, (state, action) => {
      state.topicDetail = action.payload.topic;
      state.topicCount = action.payload.vocabCount
    });
    builder.addCase(getTopicByUser.fulfilled, (state, action) => {
      state.topicList = action.payload.topics;
    });
  },
});

export default wordformSlice.reducer;
