import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { CheckOutlined } from "@ant-design/icons"; // Import CheckOutlined
import Countdown from "react-countdown";
import { dispatch, useSelector } from "../../redux/store";
import { getVocabByUserId, } from "../../redux/action/vocab";

const { Title, Text } = Typography;
function GrammarAnswer() {
  const state = useAuth();
  const user = state.user;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  const [words, setWords] = useState<string[]>([]);
  const [shuffledWords, setShuffledWords] = useState<string[][]>([]);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTestComplete, setIsTestComplete] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const testDuration = 300000;
  const { vocabList } = useSelector((state) => state.vocab);

  useEffect(() => {
    if (user) {
      dispatch(getVocabByUserId({ userId: user._id, pageSize: 12 }));
    }
  }, [user]);

  useEffect(() => {
    const shuffledWordArray = vocabList
      .map((word) => word.name)
      .sort(() => 0.5 - Math.random());
    setWords(shuffledWordArray);
    const shuffledLettersArray = shuffledWordArray.map((word) =>
      word.split("").sort(() => 0.5 - Math.random())
    );
    setShuffledWords(shuffledLettersArray);
    setUserAnswers(Array(shuffledWordArray.length).fill(""));
    setStartTime(Date.now());
  }, [vocabList]);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    setIsTestComplete(true);
  };

  const getMeaningByWord = (word: string) => {
    const foundWord = vocabList.find((vocab) => vocab.name === word);
    return foundWord ? foundWord.meaning : "N/A";
  };

  const handleInputChange = (value: string) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = value;
    setUserAnswers(newUserAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < words.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // If it is the last question, mark the test as complete
      setIsTestComplete(true);
      setModalResult(true);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <span>Hết giờ!</span>;
    } else {
      return (
        <div
          style={{
            border: "3px solid #ff3b30",
            borderRadius: "16px",
            padding: "4px 12px",
            display: "inline-block",
            backgroundColor: "#fff1f0",
            boxShadow: "0 6px 12px rgba(255, 59, 48, 0.4)",
            fontWeight: 700,
            color: seconds % 2 === 0 ? "#d32f2f" : "#ff5722",
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "1px",
            textAlign: "center",
            transition: "color 0.5s ease",
            animation: seconds % 2 === 0 ? "pulse 1s infinite" : "none",
          }}
        >
          <span>{minutes}:</span>
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </div>
      );
    }
  };

  const getMaskedWord = (originalWord: string, input: string) => {
    let maskedWord = originalWord.split("");
    let inputArray = input.split("");

    inputArray.forEach((char) => {
      const index = maskedWord.indexOf(char);
      if (index !== -1) {
        maskedWord[index] = "_"; // Replace matching character with "_"
      }
    });

    // Shuffle the masked word
    return shuffleArray(maskedWord).join(" ");
  };

  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap
    }
    return array;
  };

  const handleSubmit = () => {
    handleNext();
  };
  const splitWordsIntoColumns = (
    array: string[],
    columnCount: number
  ): string[][] => {
    // Create arrays for each column with type annotation
    const columns: string[][] = new Array(columnCount).fill(null).map(() => []);

    array.forEach((word, index) => {
      const columnIndex = index % columnCount; // Determine which column it goes into
      columns[columnIndex].push(word);
    });

    return columns; // Now the words are ordered across rows horizontally
  };

  const renderCharacterComparison = (correctWord: any, userAnswer: any) => {
    return correctWord.split("").map((char: any, index: any) => {
      const isCorrect = userAnswer[index] === char;
      return (
        <Text
          key={index}
          style={{
            color: isCorrect ? "green" : "red",
            fontWeight: isCorrect ? "bold" : "normal",
          }}
        >
          {userAnswer[index] || "_"}{" "}
          {/* Show underscore if there's no user input */}
        </Text>
      );
    });
  };

  const columns = splitWordsIntoColumns(words, 4);

  return (
    <div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        closable={false}
        centered
        width={600}
        footer={false}
      >
        <Flex vertical>
          <Flex justify="space-between" align="center">
            <h3 style={{ margin: 0, color: "#444", fontWeight: 500 }}>
              Bài kiểm tra từ vựng
            </h3>
            <div style={{ marginLeft: "10px", color: "#888" }}>
              {startTime && (
                <Countdown
                  date={startTime + testDuration}
                  renderer={renderer}
                />
              )}
            </div>
          </Flex>

          {!isTestComplete && shuffledWords[currentQuestionIndex] && (
            <div>
              <h4 style={{ margin: 0 }}>
                Câu hỏi {currentQuestionIndex + 1} / {words.length}
              </h4>
              <Flex justify="center" align="center" vertical>
                <p style={{ fontWeight: 500 }}>
                  {getMaskedWord(
                    words[currentQuestionIndex],
                    userAnswers[currentQuestionIndex]
                  )}
                </p>
                <span
  style={{
    color: "#555",
    textAlign:"center",
    marginTop:10
  }}
  dangerouslySetInnerHTML={{
    __html: `${getMeaningByWord(words[currentQuestionIndex])}`,
  }}
/>

              </Flex>
              <Input
                placeholder="Nhập từ đúng"
                value={userAnswers[currentQuestionIndex]}
                onChange={(e) => handleInputChange(e.target.value)}
                style={{ width: "100%", marginTop: 20, marginBottom: 22 }}
              />
              <Flex justify="space-between">
                <div>
                  <Button
                    disabled={currentQuestionIndex === 0}
                    onClick={handleBack}
                    style={{ marginRight: "10px" }}
                  >
                    Quay lại
                  </Button>
                  <Button
                    disabled={currentQuestionIndex === words.length - 1}
                    onClick={handleNext}
                  >
                    Tiếp tục
                  </Button>
                </div>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={handleSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Nộp bài
                </Button>
              </Flex>
            </div>
          )}
        </Flex>
      </Modal>
      <Modal
        open={modalResult}
        centered
        width={1000}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "30px" }}
      >
        <div>
          {isTestComplete && (
            <>
              <Title
                level={3}
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                Kết quả bài kiểm tra:
              </Title>
              <Row gutter={[16, 16]}>
                {columns.map((column, colIndex) => (
                  <Col key={colIndex} span={6}>
                    {column.map((word, index) => {
                      const globalIndex = colIndex + index * columns.length; // To maintain the correct word ordering
                      const userAnswer = userAnswers[globalIndex] || "";
                      const isCorrect = userAnswer === word;

                      return (
                        <Card
                          key={globalIndex}
                          style={{
                            marginBottom: "16px",
                            borderRadius: "8px",
                            border: isCorrect
                              ? "2px solid green"
                              : "2px solid red",
                          }}
                        >
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Space
                              direction="horizontal"
                              style={{
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text strong>Từ {globalIndex + 1}</Text>
                              <Text
                                style={{
                                  color: isCorrect ? "green" : "red",
                                  fontWeight: "bold",
                                }}
                              >
                                {isCorrect ? "Đúng" : `Sai`}
                              </Text>
                            </Space>
                            <p style={{ textAlign: "center" }}>{word}</p>
                            <div style={{ textAlign: "center" }}>
                              {renderCharacterComparison(word, userAnswer)}
                            </div>
                          </Space>
                        </Card>
                      );
                    })}
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default GrammarAnswer;
