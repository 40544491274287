import React from "react";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import VocabContainer from "./VocabContainer";
import { useTranslation } from "react-i18next";

export default function Vocab() {
  const { t } = useTranslation();

  return (
    <div>
      <TitleComponent
        title={t("vocab")}
        type="primary"
        data={t("add")}
        to="/Vocab/Create"
      />
      <VocabContainer/>
    </div>
  );
}
