import React from "react";

import { useTranslation } from "react-i18next";

import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import VocabForm from "./Form/VocabForm";

function VocabCreate() {
    const {t} = useTranslation()
  return (
    <div>
      <TitleComponent title={t('add')} isExist={false} />
      <VocabForm/>
    </div>
  );
}

export default VocabCreate;
