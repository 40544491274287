import { useMemo } from "react";
import { Switch, Dropdown } from "antd";
import { CalendarOutlined, DeleteOutlined, EditOutlined, SoundOutlined } from "@ant-design/icons";
import { t } from "i18next";
import ElipsCustom from "../../accset/svg/elips";
import useVocabContainer from "./VocabContainer";
import { Link } from "react-router-dom";

interface CategoryColors {
  "Tính từ": { text: string; background: string };
  "Trạng từ": { text: string; background: string };
  "Danh từ": { text: string; background: string };
  "Động từ": { text: string; background: string };
  "Đại từ": { text: string; background: string };
  "Giới từ": { text: string; background: string };
  "Liên từ": { text: string; background: string };
}

const categoryColors: CategoryColors = {
  "Tính từ": { text: "#FF6F61", background: "#FFE9E6" },
  "Trạng từ": { text: "#6BBF74", background: "#E4F8E4" },
  "Danh từ": { text: "#4B6DFF", background: "#E0E8FF" },
  "Động từ": { text: "#9B59B6", background: "#F3E5F5" },
  "Đại từ": { text: "#F47F7F", background: "#FEECEC" },
  "Giới từ": { text: "#5A9BF4", background: "#E4F1FF" },
  "Liên từ": { text: "#6BFC6B", background: "#E2FCE2" },
};

const statusColor = {
  "Đã nhớ": { text: "#28a745", background: "#d4edda" },
  "Chưa nhớ": { text: "#dc3545", background: "#f8d7da" },
  "Từ mới": { text: "#17a2b8", background: "#d1ecf1" },
};

interface VocabTableProps {
  showModalDetail: (id: string) => void;
  showModalDelete: (id: string) => void;
}

export const useVocabTable = ({ showModalDetail, showModalDelete }: VocabTableProps) => {
  const { handleCollapseChange, handleSwitchChange } = useVocabContainer();

  const columns = useMemo(
    () => {
      const itemsDrop = (id: string) => [
        {
          key: "1",
          label: (
            <div onClick={() => showModalDetail(id)}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              {t("detail")}
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <Link to={`/Vocab/Edit/${id}`} style={{ display: "flex", alignItems: "center" }}>
              <EditOutlined style={{ marginRight: 8 }} />
              {t("edit")}
            </Link>
          ),
        },
        {
          key: "3",
          label: (
            <div onClick={() => showModalDelete(id)}>
              <span>
                <DeleteOutlined style={{ marginRight: 8 }} />
                {t("delete")}
              </span>
            </div>
          ),
        },
      ];

      return [
        {
          title: t("vocab"),
          dataIndex: "name",
          key: "name",
          minWidth: 200,
          render: (name: string, record: any) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "color 0.3s",
              }}
              onClick={() => handleCollapseChange(name)}
              onMouseEnter={(e) => (e.currentTarget.style.color = "blue")}
              onMouseLeave={(e) => (e.currentTarget.style.color = "black")}
            >
              <span style={{ marginRight: "8px", fontWeight: 400 }}>{name}</span>
              <SoundOutlined
                style={{ cursor: "pointer" }}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  handleCollapseChange(name);
                }}
              />
            </div>
          ),
        },
        {
          title: t("transcrip"),
          dataIndex: "transcription",
          key: "transcription",
          minWidth: 150,
        },
        {
          title: t("wordform"),
          dataIndex: "wordform",
          key: "wordform",
          width: 180,
          render: (text: string | undefined) => {
            if (!text) return <span>No data</span>;
            const categoryNames = text.split(", ").map((name) => name.trim());

            return (
              <div>
                {categoryNames.map((name: string, index: number) => (
                  <div
                    key={index}
                    style={{
                      color: categoryColors[name as keyof CategoryColors]?.text || "#000000",
                      backgroundColor: categoryColors[name as keyof CategoryColors]?.background || "#FFFFFF",
                      padding: "3px 8px",
                      borderRadius: "10px",
                      display: "inline-block",
                      marginRight: index < categoryNames.length - 1 ? "10px" : "0",
                    }}
                  >
                    {name}
                  </div>
                ))}
              </div>
            );
          },
        },
        {
          title: t("meaning"),
          dataIndex: "meaning",
          key: "meaning",
          minWidth: 150,
          render: (text: string) => (
            <div className="truncate-two-lines" dangerouslySetInnerHTML={{ __html: text }} />
          ),
        },
        {
          title: t("status"),
          dataIndex: "isRemember",
          key: "isRemember",
          width: 120,
          render: (isRemember: boolean) => {
            let text = "Từ mới";
            let textColor = statusColor["Từ mới"].text;
            let backgroundColor = statusColor["Từ mới"].background;

            if (isRemember === true) {
              text = "Đã nhớ";
              textColor = statusColor["Đã nhớ"].text;
              backgroundColor = statusColor["Đã nhớ"].background;
            } else if (isRemember === false) {
              text = "Chưa nhớ";
              textColor = statusColor["Chưa nhớ"].text;
              backgroundColor = statusColor["Chưa nhớ"].background;
            }

            return (
              <div
                style={{
                  color: textColor,
                  backgroundColor: backgroundColor,
                  padding: "3px 8px",
                  borderRadius: "10px",
                  display: "inline-block",
                }}
              >
                {text}
              </div>
            );
          },
        },
        {
          title: t("remember"),
          dataIndex: "isRemember",
          key: "isRememberSwitch",
          minWidth: 150,
          render: (text: string, record: any) => (
            <div style={{ width: "50px", margin: "0 auto" }}>
              <Switch
                checked={record.isRemember === true}
                onChange={(checked) => handleSwitchChange(checked, record.key)}
              />
            </div>
          ),
        },
        {
          title: t("action"),
          dataIndex: "action",
          key: "action",
          minWidth: 150,
          render: (text: string, record: any) => (
            <div style={{ width: "50px", margin: "0 auto" }}>
              <Dropdown menu={{ items: itemsDrop(record.key) }} trigger={["hover"]}>
                <span>
                  <ElipsCustom />
                </span>
              </Dropdown>
            </div>
          ),
        },
      ];
    },
    [handleSwitchChange, handleCollapseChange,showModalDelete,showModalDetail]
  );

  return {
    columns,
  };
};
