import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './configRouter';
import { useAuth } from '../component/context/AuthContext';
import Loading from '../component/global/item/Loading/loading';
import Page404 from '../component/pages/NotFound/page404';

const AppRoutes = () => {
  const { hasValidToken, validateToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      await validateToken();
      setIsLoading(false);
    };

    checkToken();
  }, [validateToken]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.requiresAuth && !hasValidToken
              ? <Navigate to="/sign-in" replace />
              : route.element
          }
        >
          {route.children?.map((childRoute, childIndex) => (
            <Route
              key={childIndex}
              path={childRoute.path}
              element={
                childRoute.requiresAuth && !hasValidToken
                  ? <Navigate to="/sign-in" replace />
                  : childRoute.element
              }
            />
          ))}
        </Route>
      ))}
      <Route path="/" element={<Navigate to={hasValidToken ? "" : "/sign-in"} replace />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
