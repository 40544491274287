import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Modal,
  Select,
  Table,
  notification,
} from "antd";
import "./TableComponent.scss";

import CustomPagination from "../pagination-custom/PaginationCustom";
import { useTranslation } from "react-i18next";

type Prop = {
  columns: any;
  data: any;
  totalPage?: number;
  pageIndex?: number;
  pageSize: number;
  onChangePage: (pageIndex: number, pageSize: number) => void;
  importOnClick?: (file: any) => void;
  exportOnClick?: () => void;
  isPrimary?: boolean;
};

const TableComponent = ({
  columns,
  data,
  totalPage,
  pageIndex = 1,
  pageSize = 12,
  onChangePage,
}: Prop) => {
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [customPageSizeInput, setCustomPageSizeInput] = useState("");
  const {t} = useTranslation()
  const [params, setParams] = useState({
    pageSize: pageSize,
    pageIndex: pageIndex,
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: pageSize, // Update pageSize if prop changes
    }));
  }, [pageSize]);

  const showNotification = () => {
    notification.error({
      className: "notification__item notification__item--error",
      message: "Số Item vượt quá tổng số",
      duration: 3,
    });
  };

  const showNotificationError = () => {
    notification.error({
      className: "notification__item notification__item--error",
      message: "Vui lòng nhập số item",
      duration: 3,
    });
  };

  const showModalSelect = () => {
    setIsModalSelect(true);
  };

  const handleOkSelect = () => {
    if (customPageSizeInput === "") {
      showNotificationError();
      return;
    }
    const newPageSize = parseInt(customPageSizeInput);
    if (!isNaN(newPageSize)) {
      let updatedPageSize = newPageSize;

      if (typeof totalPage !== "undefined" && newPageSize > totalPage) {
        updatedPageSize = totalPage;
        showNotification();
      }

      setParams({
        ...params,
        pageSize: updatedPageSize,
      });

      onChangePage(params.pageIndex, updatedPageSize);
    }
    setIsModalSelect(false);
  };

  const handleCancelSelect = () => {
    setIsModalSelect(false);
  };

  const handlePageChange = (pageIndex: any, pageSize: any) => {
    setParams({
      ...params,
      pageIndex,
    });
    onChangePage(pageIndex, params.pageSize);
  };

  const handleSelectChange = (value: any) => {
    if (value === "openModal") {
      showModalSelect();
    } else {
      setParams({
        ...params,
        pageSize: value,
      });
      onChangePage(params.pageIndex, value);
    }
  };

  const handleCustomPageSizeInputChange = (e: any) => {
    const { value } = e.target;
    setCustomPageSizeInput(value);
  };

  return (
    <div className="table">
      <Card className="table__card layoutItem">        
        <Table
          sticky
          className="table__table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
       
      </Card>
      <div className="table__footer">
         <div className="table__footer__col">
           <p className="table__footer__col__title">{t("line")}</p>
           <Select
             className="table__footer__select"
             style={{ width: 58 }}
             value={params.pageSize}
             onChange={handleSelectChange}
             bordered={false}
             suffixIcon={null}
           >
             <Select.Option value={5}>5</Select.Option>
             <Select.Option value={12}>12</Select.Option>
             <Select.Option value={20}>20</Select.Option>
             <Select.Option value="openModal">Nhập</Select.Option>
           </Select>
           <CustomPagination
             current={params.pageIndex}
             total={totalPage}
             pageSize={params.pageSize}
             onChange={handlePageChange}
           />
         </div>
       </div>
      <Modal
        centered
        open={isModalSelect}
        onOk={handleOkSelect}
        onCancel={handleCancelSelect}
        className="modal__product modal__selectPagination"
        okType={"danger"}
      >
        <Form
          name="basic"
          requiredMark={"optional"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Số item trên trang"
            name="username"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số!",
              },
              {
                validator: (_, value) => {
                  const numberValue = parseInt(value, 10);
                  if (isNaN(numberValue) || numberValue <= 0) {
                    return Promise.reject("Vui lòng nhập số lớn hơn 0");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              className="modal__selectPagination__input"
              placeholder="Số item"
              value={customPageSizeInput}
              onChange={handleCustomPageSizeInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TableComponent;
